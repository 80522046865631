import { iResponseCrud, iTable } from '../../../model/app/Application';
import { formatDate } from '../../../shared/functions/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const IrrelevanceTable = ({
  listOfAllData,
  getCurrentDataID,
  filterData,
}: iTable) => {
  const { t } = useTranslation();
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">
            {t('Irrelevance.list_of_irrelevances')}
          </h4>
        </div>
        <p className="tx-12 tx-gray-500 mb-2">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>
      </div>
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-end position-relative mb-4">
          <input
            className="form-control w-md-25"
            name="reason"
            onChange={filterData}
            placeholder="Search..."
            type="text"
            maxLength={30}
          />
          <i
            className="fas fa-search position-absolute"
            style={{ right: '20px' }}
          />
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-hover mb-0 text-md-nowrap">
            <thead>
              <tr>
                <th>ID</th>
                <th>{t('Irrelevance.reason')}</th>
                <th>{t('Irrelevance.created_at')}</th>
                <th>{t('Irrelevance.updated_at')}</th>
                <th>{t('Irrelevance.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {listOfAllData!?.length > 0 ? (
                listOfAllData?.map((data: iResponseCrud) => {
                  return (
                    <tr key={data.id}>
                      <th scope="row">{data.id}</th>
                      <td>{data.reason ? data.reason : '-'}</td>
                      <td>{formatDate(data.createdAt)}</td>
                      <td>{formatDate(data.updatedAt)}</td>
                      <td className="d-flex justify-content-center">
                        <button
                          className="btn btn-secondary ripple me-1"
                          onClick={() => getCurrentDataID!(data.id)}
                          data-bs-target="#deleteModal"
                          data-bs-toggle="modal"
                        >
                          {t('Irrelevance.delete')}
                        </button>
                        <button
                          className="btn btn-primary ripple"
                          onClick={() => getCurrentDataID!(data.id)}
                          data-bs-target="#modaldemo1"
                          data-bs-toggle="modal"
                        >
                          {t('Irrelevance.change')}
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
