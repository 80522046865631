import React, { useEffect, useState } from 'react';
import {
  iSelectedModalData,
  iTenderFiltering,
} from '../../../model/app/Application';
import { TenderTable } from './TenderTable';
import Select from 'react-select';
import { getPossibleSupervisors } from '../../companies/Companies.service';
import { useTranslation } from 'react-i18next';

export const themeSelect = (theme: any) => {
  return {
    ...theme,
    borderRadius: 5,
    minHeight: 40,
    colors: {
      ...theme.colors,
      text: 'red',
      primary25: '#5cd3b9',
      primary: '#38cab3',
    },
  };
};

export const TenderFiltering = ({
  filterData,
  filterTenderStatus,
  tenderStatus,
  getCurrentDataID,
  setShowForm,
  scrollIntoView,
  setShowEditTender,
  listOfAllData,
  companyList,
  clientList,
  deleteTender,
  isDeletedList,
}: iTenderFiltering) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [possibleSupervisor, setPossibleSupervisor] = useState([]);
  const { t } = useTranslation();
  const optionsCompany = companyList?.map((company: iSelectedModalData) => {
    return {
      value: company?.id,
      label: company?.title,
    };
  });

  const changeSelectHandler = (e: any) => {
    setSelectedCompany(e?.value);
  };

  const optionsSupervisor = possibleSupervisor?.map(
    (supervisor: iSelectedModalData) => {
      return {
        value: supervisor?.id,
        label: `${supervisor?.firstName} ${supervisor?.lastName}`,
      };
    }
  );

  const optionsClient = clientList?.map((client: iSelectedModalData) => {
    return {
      value: client?.id,
      label: client?.name,
    };
  });

  useEffect(() => {
    if (+selectedCompany!) {
      getPossibleSupervisors(+selectedCompany!)
        .then((res) => {
          setPossibleSupervisor(res);
        })
        .catch((error) => error);
    }
  }, [selectedCompany]);

  return (
    <div className="tab-content">
      <div className="tab-pane active" id="tab0">
        <div className="row">
          <div className="col-md-3 d-flex flex-column">
            <label htmlFor="title">{t('Tenders.title')}</label>
            <div className="d-flex align-items-center">
              <input
                className="form-control w-100"
                name="title"
                id="title"
                onChange={filterData}
                placeholder={t('Tenders.search')}
                type="text"
                maxLength={30}
              />
              <i className="fas fa-search " style={{ marginLeft: '-1.5rem' }} />
            </div>
          </div>

          <div className="col-md-2">
            <label htmlFor="commissioner">
              {t('Tenders.won')} / {t('Tenders.lost')}
            </label>
            <select
              name="commissioner"
              className="form-control form-select select2 w-100"
              onChange={(e) => filterTenderStatus('', 'isWon', e)}
              data-bs-placeholder="Select status"
            >
              <option value="">{t('Tenders.all')}</option>
              <option value="true">{t('Tenders.won')}</option>
              <option value="false">{t('Tenders.lost')}</option>
            </select>
          </div>

          <div className="col-md-2">
            <label htmlFor="submitted">{t('Tenders.submitted')}</label>
            <select
              name="submitted"
              className="form-control form-select select2 w-100"
              onChange={(e) => filterTenderStatus('', 'isSubmitted', e)}
              data-bs-placeholder="Select status"
            >
              <option value="">{t('Tenders.all')}</option>
              <option value="true">{t('Tenders.yes')}</option>
              <option value="false">{t('Tenders.no')}</option>
            </select>
          </div>

          <div className="col-md-1">
            <label htmlFor="isRelevant">{t('Tenders.relevant')}</label>
            <select
              name="isRelevant"
              className="form-control form-select select2 w-100"
              onChange={(e) => filterTenderStatus('', 'isRelevant', e)}
              data-bs-placeholder="Select status"
            >
              <option value="">{t('Tenders.all')}</option>
              <option value="true">{t('Tenders.yes')}</option>
              <option value="false">{t('Tenders.no')}</option>
            </select>
          </div>

          {/* <div
            className={`${selectedCompany === null ? 'col-md-3' : 'col-md-2'}`}
          >
            <label htmlFor='client'>Client</label>
            <Select
              options={optionsClient}
              onChange={(e) => filterTenderStatus('', 'client', e?.value)}
              id='custom-select2'
              isClearable={true}
              theme={themeSelect}
            />
          </div> */}

          <div
            className={`${selectedCompany === null ? 'col-md-3' : 'col-md-2'}`}
          >
            <label htmlFor="company">{t('Tenders.company')}</label>
            <Select
              options={optionsCompany}
              onChange={(e) => changeSelectHandler(e)}
              id="custom-select2"
              isClearable={true}
              theme={themeSelect}
            />
          </div>

          {selectedCompany !== null && (
            <div className="col-md-2">
              <label htmlFor="supervisor">{t('Tenders.supervisor')}</label>
              <Select
                options={optionsSupervisor}
                onChange={(e) =>
                  filterTenderStatus('', 'supervisor', e!?.value)
                }
                id="custom-select2"
                isClearable={true}
                theme={themeSelect}
              />
            </div>
          )}
        </div>

        <TenderTable
          listOfAllData={listOfAllData}
          getCurrentDataID={getCurrentDataID}
          setShowForm={setShowForm}
          deleteTender={deleteTender}
          scrollIntoView={scrollIntoView}
          setShowEditTender={setShowEditTender}
          isDeletedList={isDeletedList}
        />
      </div>
      {tenderStatus?.map((i: number) => {
        return (
          <div key={i} className="tab-pane" id={`tab${i + 1}`}>
            <TenderTable
              listOfAllData={listOfAllData}
              getCurrentDataID={getCurrentDataID}
              setShowForm={setShowForm}
              scrollIntoView={scrollIntoView}
              setShowEditTender={setShowEditTender}
            />
          </div>
        );
      })}
    </div>
  );
};
