import { iCrud, iGetData } from '../../model/app/Application';
import { requests } from '../../utils/helpers/api.services';
import { BASE_PATH } from '../../utils/helpers/api.routes';
import { ErrorToast } from '../../utils/toasters/toasters';

export function addUser(body: any) {
  return requests
    .post(`${BASE_PATH}/admin/users`, body)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function getUser(id: number) {
  return requests
    .get(`${BASE_PATH}/admin/users/${id}`)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function putUser(id: number, body?: iCrud) {
  return requests
    .put(`${BASE_PATH}/admin/users/${id}`, body)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function getUsers(body?: iGetData) {
  return requests
    .get(`${BASE_PATH}/admin/users`, body)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function deleteUser(id: number) {
  return requests
    .delete(`${BASE_PATH}/admin/users/${id}`)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function putPasswordUser(id: number, body: iCrud | undefined) {
  return requests
    .put(`${BASE_PATH}/admin/users/change-password/${id}`, body)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function putUserStatus(id: number) {
  return requests
    .put(`${BASE_PATH}/admin/users/change-is-active-status/${id}`)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}
