import { iCrud, iGetData } from '../../model/app/Application';
import { requests } from '../../utils/helpers/api.services';
import { BASE_PATH } from '../../utils/helpers/api.routes';
import { ErrorToast } from '../../utils/toasters/toasters';

export function addTender(body: iCrud) {
  return requests
    .post(`${BASE_PATH}/admin/tenders`, body)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function getTender(id: number) {
  return requests
    .get(`${BASE_PATH}/admin/tenders/${id}`)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function getTenders(body?: iGetData) {
  return requests
    .get(`${BASE_PATH}/admin/tenders`, body)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function getDeletedTenders(body: any) {
  return requests
    .get(`${BASE_PATH}/admin/tenders/deleted-tenders`, body)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function putTenders(id: number, body?: iCrud) {
  return requests
    .put(`${BASE_PATH}/admin/tenders/${id}`, body)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function deleteRestoreTender(id: number) {
  return requests
    .put(`${BASE_PATH}/admin/tenders/delete-restore/${id}`)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function deleteTender(id: number) {
  return requests
    .delete(`${BASE_PATH}/admin/tenders/${id}`)
    .then((data) => data)
    .catch((error) => ErrorToast(error));
}

export function getTenderStatuses() {
  return requests
    .get(`${BASE_PATH}/enums`)
    .then((data) => data?.tenderStatus)
    .catch((error) => ErrorToast(error));
}
