import { Link, useLocation } from 'react-router-dom';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import FunctionsIcon from '@mui/icons-material/Functions';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { Apartment, DocumentScanner, Groups } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export function SideBar() {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <div className="sticky">
        <aside className="app-sidebar">
          <div className="main-sidebar-header active">
            <Link className="header-logo active" to="/main/dashboard">
              <img
                src="/images/bbi-logo-blue.png"
                className="main-logo  desktop-logo w-75"
                alt="logo"
              />
              <img
                src="/images/logo-white.png"
                className="main-logo  desktop-dark"
                alt="logo"
              />
              <img
                src="/images/bbi-logo-mobile.png"
                className="main-logo  mobile-logo"
                alt="logo"
              />
              <img
                src="/images/favicon-white.png"
                className="main-logo  mobile-dark"
                alt="logo"
              />
            </Link>
          </div>
          <div className="main-sidemenu">
            <ul className="side-menu">
              <li className="side-item side-item-category">
                {t('Sidebar.main')}
              </li>
              <li className="slide">
                <Link
                  className={`${
                    location.pathname === '/main/dashboard' && 'active'
                  } side-menu__item`}
                  data-bs-toggle="slide"
                  to="/main/dashboard"
                >
                  <AppsOutlinedIcon className="text-muted me-2" />
                  <span className="side-menu__label">
                    {t('Sidebar.dashboard')}
                  </span>
                  <i className="angle fe fe-chevron-right" />
                </Link>
              </li>

              <li className="side-item side-item-category">
                {t('Sidebar.tenders')}
              </li>
              <li className="slide">
                <Link
                  className={`${
                    location.pathname === '/tenders' && 'active'
                  } side-menu__item`}
                  data-bs-toggle="slide"
                  to="/tenders"
                >
                  <DocumentScanner className="text-muted me-2" />
                  <span className="side-menu__label">
                    {t('Sidebar.tenders')}
                  </span>
                  <i className="angle fe fe-chevron-right" />
                </Link>
              </li>
              <li className="slide">
                <Link
                  className={`${
                    location.pathname === '/tenders/companies' && 'active'
                  } side-menu__item`}
                  data-bs-toggle="slide"
                  to="/tenders/companies"
                >
                  <Apartment className="text-muted me-2" />
                  <span className="side-menu__label">
                    {' '}
                    {t('Sidebar.companies')}
                  </span>
                  <i className="angle fe fe-chevron-right" />
                </Link>
              </li>
              <li className="slide">
                <Link
                  className={`${
                    location.pathname === '/tenders/users' && 'active'
                  } side-menu__item`}
                  data-bs-toggle="slide"
                  to="/tenders/users"
                >
                  <Groups className="text-muted me-2" />
                  <span className="side-menu__label">{t('Sidebar.users')}</span>
                  <i className="angle fe fe-chevron-right" />
                </Link>
              </li>

              <li className="side-item side-item-category">
                {t('Sidebar.lists')}
              </li>
              <li className="slide">
                <Link
                  className={`${
                    location.pathname === '/lists/commissioner' && 'active'
                  } side-menu__item`}
                  data-bs-toggle="slide"
                  to="/lists/commissioner"
                >
                  <PersonOutlineOutlinedIcon className="text-muted me-2" />
                  <span className="side-menu__label">
                    {t('Sidebar.commissioner')}
                  </span>
                </Link>
              </li>
              <li className="slide">
                <Link
                  className={`${
                    location.pathname === '/lists/location' && 'active'
                  } side-menu__item`}
                  data-bs-toggle="slide"
                  to="/lists/location"
                >
                  <MapOutlinedIcon className="text-muted me-2" />
                  <span className="side-menu__label">
                    {t('Sidebar.location')}
                  </span>
                </Link>
              </li>
              <li className="slide">
                <Link
                  className={`${
                    location.pathname === '/lists/type' && 'active'
                  } side-menu__item`}
                  data-bs-toggle="slide"
                  to="/lists/type"
                >
                  <ExtensionOutlinedIcon className="text-muted me-2" />
                  <span className="side-menu__label">{t('Sidebar.type')}</span>
                </Link>
              </li>
              {/* <li className="slide">
                                <Link className={`${location.pathname === '/lists/client' && 'active'} side-menu__item`}
                                      data-bs-toggle="slide" to="/lists/client">
                                    <PersonSearchOutlinedIcon className="text-muted me-2"/>
                                    <span className="side-menu__label">Client</span>
                                </Link>
                            </li> */}
              <li className="slide">
                <Link
                  className={`${
                    location.pathname === '/lists/irrelevance' && 'active'
                  } side-menu__item`}
                  data-bs-toggle="slide"
                  to="/lists/irrelevance"
                >
                  <InsertChartOutlinedIcon className="text-muted me-2" />
                  <span className="side-menu__label">
                    {t('Sidebar.irrelevance')}
                  </span>
                </Link>
              </li>
              <li className="slide">
                <Link
                  className={`${
                    location.pathname === '/lists/cancellation' && 'active'
                  } side-menu__item`}
                  data-bs-toggle="slide"
                  to="/lists/cancellation"
                >
                  <CancelPresentationOutlinedIcon className="text-muted me-2" />
                  <span className="side-menu__label">
                    {t('Sidebar.cancellation')}
                  </span>
                </Link>
              </li>
              <li className="slide">
                <Link
                  className={`${
                    location.pathname === '/lists/loss' && 'active'
                  } side-menu__item`}
                  data-bs-toggle="slide"
                  to="/lists/loss"
                >
                  <IndeterminateCheckBoxOutlinedIcon className="text-muted me-2" />
                  <span className="side-menu__label">{t('Sidebar.loss')}</span>
                </Link>
              </li>
              <li className="slide">
                <Link
                  className={`${
                    location.pathname === '/lists/functions' && 'active'
                  } side-menu__item`}
                  data-bs-toggle="slide"
                  to="/lists/functions"
                >
                  <FunctionsIcon className="text-muted me-2" />
                  <span className="side-menu__label">
                    {t('Sidebar.functions')}
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </>
  );
}
