import moment from "moment";

export const searchDto = (state: any) => {
    const output: any = {}
    for (const s in state) {
        if (state[s] || state[s]?.length) output[s] = state[s]
    }
    return output
}

export function formatDate(date: string) {
    return moment(date).format('DD.MM.YYYY - HH:mm');
}
