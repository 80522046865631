import React, {useEffect, useRef, useState} from "react";
import _debounce from "lodash/debounce";
import {SuccessToast} from "../../../utils/toasters/toasters";
import {searchDto} from "../../functions/Functions";
import {
    iCrud,
    iPagination,
    iResponseCrud,
    iSelectedModalData,
    iUseTenders
} from "../../../model/app/Application";
import {deleteCompanyDocument} from "../../../app/companies/Companies.service";


export function useTender({getData, putData, deleteData, getMany, addData}: iUseTenders) {
    const inputRef = useRef<any>(null);
    const modalRef = useRef<any>(null);

    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState<iPagination>({
        page: 1,
        perPage: 10,
        name: '',
        title: '',
        reason: '',
        email: '',
        client: '',
        supervisor: '',
        company: '',
        status: '',
        isRelevant: false,
        isWon: false,
        isSubmitted: false
    })


    const [showForm, setShowForm] = useState(false);
    const [dataState, setDataState] = useState<iCrud>();
    const [listOfAllData, setListOfAllData] = useState<iResponseCrud[]>()
    const [selectedData, setSelectedData] = useState<iCrud>();
    const [selectedModalData, setSelectedModalData] = useState<iSelectedModalData>();
    const actionName = getData?.name.substring(3);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if (event.target.name === 'maxRevenue') {
            setSelectedData((prev: iCrud | undefined) => ({
                ...prev,
                [event.target.name]: +event.target.value
            }))
            setDataState(prev => ({...prev, [event.target.name]: +event.target.value}))
        } else {
            setDataState(prev => ({
                ...prev,
                [event.target.name]: event.target.name === 'isProlongation' ? (event?.target as HTMLInputElement).checked : event?.target?.value
            }))
            if (getData?.name === 'getTender') {
                setSelectedData((prev: iCrud | undefined) => ({
                    ...prev,
                    tender: {
                        ...prev?.tender,
                        [event.target.name]: event.target.value
                    }
                }))
            } else {
                setSelectedData((prev: iCrud | undefined) => ({
                    ...prev,
                    [event.target.name]: event.target.value
                }))
            }
        }
    };

    const modalDataHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'file') {
            setSelectedData((prev: iCrud | undefined) => ({
                ...prev,
                [event.target.name]: event.target.value
            }))
        } else {
            setSelectedData((prev: iCrud | undefined) => ({
                ...prev,
                company: {
                    ...prev!.company,
                    [event.target.name]: event.target.value,
                }
            }))
        }
    }

    const filterData = _debounce((event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (event.target.name === 'email-filter') {
            setPagination(prev => ({...prev, email: event.target.value}))
        } else {
            setPagination(prev => ({...prev, [event.target.name]: event.target.value}))
        }
    }, 100);


    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage})
        setPage(updatePage)
    };

    const createData = () => {
        addData(dataState).then(res => {
            if (res !== undefined) {
                setDataState(prev => undefined)
                localStorage.removeItem('selectedCompanyID');
                SuccessToast(`${actionName} successfully created`);
                setShowForm(false)
            }
        }).catch(error => error);
    }

    const changeSelectHandler = (event: any) => {
        if (event?.isCompany) {
            localStorage.setItem('selectedCompanyID', event?.value);
            setDataState(prev => ({...prev, company: event?.value}))
            setSelectedData(prev => ({...prev, company: event?.value}))
        } else if (event?.isClient) {
            setDataState(prev => ({...prev, client: +event?.value}))
            setSelectedData(prev => ({...prev, client: event?.value}))
        } else if (event?.isCommissioner) {
            setSelectedData(prev => ({...prev, commissioner: event?.value}))
            setDataState(prev => ({...prev, commissioner: +event?.value}))
        } else if (event?.isLocation) {
            setSelectedData(prev => ({...prev, location: event?.value}))
            setDataState(prev => ({...prev, location: event?.value}))
        } else if (event?.isTenderType) {
            setSelectedData(prev => ({...prev, tenderType: event?.value}))
            setDataState(prev => ({...prev, tenderType: event?.value}))
        } else if (event?.isSupervisor) {
            setSelectedData(prev => ({...prev, supervisor: event?.value}))
            setDataState(prev => ({...prev, supervisor: event?.value}))
        } else if (event[0]?.isRole) {
            setDataState(prev => ({...prev, roles: event?.map((role: any) => (role.value))}))
        }
    }

    const updateData = () => {


        putData(selectedData!?.id!, selectedData!).then(res => {
            if (res) {
                const paginationState = searchDto(pagination)
                getMany(paginationState).then(data => {
                    if (data) {
                        SuccessToast(`You have successfully updated ${actionName}`)
                        setTotalPages(Math.ceil(data?.count / data?.perPage))
                        setListOfAllData(data?.data);
                    }
                }).catch(error => error);
            }
        }).catch(error => error);
    }

    const getCurrentDataID = (id: number) => {
        localStorage.setItem('selectedCompanyID', JSON.stringify(id))
        getData(id).then(res => {
            setSelectedData(res);
            setSelectedModalData(res);
        }).catch(error => error);
    }

    const removeData = () => {
        deleteData!(selectedData!?.id!).then(res => {
            if (res !== undefined) {
                const paginationState = searchDto(pagination)
                getMany(paginationState).then(data => {
                    if (data) {
                        SuccessToast(`You have successfully deleted ${actionName}`)
                        setTotalPages(Math.ceil(data?.count / data?.perPage))
                        setListOfAllData(data?.data);
                    }
                }).catch(error => error);
            }
        }).catch(error => error);
    }

    const deleteFile = (id: number) => {
        deleteCompanyDocument(id)
            .then(res => {
                if (res !== undefined) {
                    localStorage.removeItem('fileIDDelete');
                    SuccessToast('You have successfully deleted this file!')
                }
            }).catch(error => error);
    }

    useEffect(() => {
        const paginationState = searchDto(pagination)
        if (!showForm) {
            getMany(paginationState).then(data => {
                if (data) {
                    setTotalPages(Math.ceil(data?.count / data?.perPage))
                    setListOfAllData(data?.data);
                }
            }).catch(error => error);
        }
    }, [page, showForm, getMany, JSON.stringify(pagination)])


    return {
        page,
        handlePages,
        totalPages,
        listOfAllData,
        removeData,
        updateData,
        selectedModalData,
        createData,
        getCurrentDataID,
        changeHandler,
        changeSelectHandler,
        deleteFile,
        showForm,
        setShowForm,
        modalDataHandler,
        selectedData,
        filterData,
        pagination,
        inputRef,
        modalRef
    }
}