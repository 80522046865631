import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoginPage } from './authentication/login/LoginPage';
import { Dashboard } from './app/dashboard/Dashboard';
import { NotFound } from './shared/helperScreens/errors/404';
import { InternalError } from './shared/helperScreens/errors/500';
import { Commissioner } from './app/commissioner/Commissioner';
import { Location } from './app/location/Location';
import { Type } from './app/type/Type';

import { Irrelevance } from './app/Irrelevance/Irrelevance';
import { Cancellation } from './app/cancellation/Cancellation';
import { Loss } from './app/loss/Loss';
import { MyProfile } from './app/profile/myProfile/MyProfile';
import { Companies } from './app/companies/Companies';
import { Tenders } from './app/tenders/Tenders';
import { Users } from './app/users/Users';
import { Functions } from './app/functions/Functions';
import { ResetPassword } from './authentication/reset/ResetPassword';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/forgot" element={<ResetPassword />} />
        <Route path="/main/dashboard" element={<Dashboard />} />
        <Route path="/tenders" element={<Tenders />} />
        <Route path="/tenders/companies" element={<Companies />} />
        <Route path="/tenders/users" element={<Users />} />
        <Route path="/lists/commissioner" element={<Commissioner />} />
        <Route path="/lists/location" element={<Location />} />
        <Route path="/lists/type" element={<Type />} />
        <Route path="/lists/irrelevance" element={<Irrelevance />} />
        <Route path="/lists/cancellation" element={<Cancellation />} />
        <Route path="/lists/loss" element={<Loss />} />
        <Route path="/lists/functions" element={<Functions />} />
        <Route path="/profile/myProfile" element={<MyProfile />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/500" element={<InternalError />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
