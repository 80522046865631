import {iCrud, iGetData} from "../../model/app/Application";
import {requests} from "../../utils/helpers/api.services";
import {BASE_PATH} from "../../utils/helpers/api.routes";
import {ErrorToast} from "../../utils/toasters/toasters";

export function addCancellation(body: iCrud) {
    return requests.post(`${BASE_PATH}/admin/cancellations`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getCancellation(id: number) {
    return requests.get(`${BASE_PATH}/admin/cancellations/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getCancellations(body?: iGetData) {
    return requests.get(`${BASE_PATH}/admin/cancellations`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function putCancellations(id: number, body?: iCrud) {
    return requests.put(`${BASE_PATH}/admin/cancellations/${id}`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function deleteCancellation(id:number){
    return requests.delete(`${BASE_PATH}/admin/cancellations/${id}`)
        .then(data=>data)
        .catch(error=>ErrorToast(error));
}
