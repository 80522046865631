import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Modals } from '../../../shared/modals/Modals';
import React, { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
interface iUserChangePasswordModalProps {
  selectedData: any;
  showPassword: boolean;
  setShowPassword: React.Dispatch<SetStateAction<boolean>>;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeUserPassword: () => void;
}

export const UserChangePasswordModal = ({
  selectedData,
  showPassword,
  setShowPassword,
  changeHandler,
  changeUserPassword,
}: iUserChangePasswordModalProps) => {
  const { t } = useTranslation();
  return (
    <Modals
      modalId="changePasswordModal"
      title={`${t('Users.change_password_for_user')} - ID: ${selectedData?.id}`}
      body={
        <div className="form-group mb-0">
          <div className="row">
            <div className="col-md-12">
              <label htmlFor="password">{t('Users.new_password')}</label>
              <div className="d-flex align-items-center">
                <input
                  type={showPassword ? t('Users.password') : t('Users.text')}
                  className="form-control"
                  name="password"
                  onBlur={changeHandler}
                  placeholder={t('Users.enter_password')}
                />
                {showPassword ? (
                  <VisibilityOffIcon
                    onClick={() => setShowPassword(false)}
                    className="text-gray ms--2 cursor-pointer"
                  />
                ) : (
                  <VisibilityIcon
                    onClick={() => setShowPassword(true)}
                    className="text-gray ms--2 cursor-pointer"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      }
      updateChanges={changeUserPassword}
    />
  );
};
