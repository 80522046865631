import {iCrud, iGetData} from "../../model/app/Application";
import {requests} from "../../utils/helpers/api.services";
import {BASE_PATH} from "../../utils/helpers/api.routes";
import {ErrorToast} from "../../utils/toasters/toasters";

export function addIrrelevance(body: iCrud) {
    return requests.post(`${BASE_PATH}/admin/tender-irrelevancies`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getIrrelevance(id: number) {
    return requests.get(`${BASE_PATH}/admin/tender-irrelevancies/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function putIrrelevance(id: number, body?: iCrud) {
    return requests.put(`${BASE_PATH}/admin/tender-irrelevancies/${id}`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getIrrelevances(body?: iGetData) {
    return requests.get(`${BASE_PATH}/admin/tender-irrelevancies`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function deleteIrrelevance(id:number){
    return requests.delete(`${BASE_PATH}/admin/tender-irrelevancies/${id}`)
        .then(data=>data)
        .catch(error=>ErrorToast(error));
}