import { Link } from 'react-router-dom';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import { SuccessToast } from '../../utils/toasters/toasters';
import { BASE_PATH } from '../../utils/helpers/api.routes';

export const trimRoles = (roles: string[]) => {
  roles.forEach((role) => {
    if (role.slice(4).includes('COMPANY')) {
      return role.slice(12).split('_').join(' ') + `, `;
    } else {
      return role.split('_').join(' ') + `, `;
    }
  });
};

export function Header() {
  const { user } = JSON.parse(localStorage.getItem('currentUser')!);

  const logout = () => {
    localStorage.removeItem('token');
    SuccessToast('You are successfully logged out!');
  };
  return (
    <>
      <div className="main-header side-header sticky nav nav-item">
        <div className=" main-container container-fluid">
          <div className="main-header-left ">
            <Link
              to="/main/dashboard"
              className="header-logo d-flex justify-content-center d-md-none"
            >
              <img
                src="/images/bbi-logo.png"
                className="mobile-logo logo-1 w-40 "
                alt="logo"
              />
              <img
                src="/images/logo-white.png"
                className="mobile-logo dark-logo-1 d-none"
                alt="logo"
              />
            </Link>
            <div
              className="app-sidebar__toggle d-flex align-items-center justify-content-center"
              data-bs-toggle="sidebar"
            >
              <div className="open-toggle">
                <MenuOpenOutlinedIcon className="text-muted cursor-pointer" />
              </div>
              <div className="close-toggle">
                <CloseOutlinedIcon className="text-muted cursor-pointer" />
              </div>
            </div>
            <div className="logo-horizontal">
              <Link to="/main/dashboard" className="header-logo">
                <img
                  src="/images/bbi-logo.png"
                  className="mobile-logo logo-1"
                  alt="logo"
                />
                <img
                  src="/images/logo-white.png"
                  className="mobile-logo dark-logo-1"
                  alt="logo"
                />
              </Link>
            </div>
          </div>
          <div className="main-header-right">
            <button
              className="navbar-toggler navresponsive-toggler d-md-none ms-auto"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent-4"
              aria-controls="navbarSupportedContent-4"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fe fe-more-vertical" />
            </button>
            <div className="mb-0 navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <ul className="nav nav-item header-icons navbar-nav-right ms-auto">
                  <li className="dropdown nav-item">
                    <a
                      className="new nav-link"
                      target="_blank"
                      rel="noopener"
                      href={process.env.REACT_APP_APP_URL}
                    >
                      <HomeWorkOutlinedIcon className="tx-gray-500" />
                    </a>
                  </li>
                  <li className="nav-link search-icon d-lg-none d-block">
                    <form className="navbar-form" role="search">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                        />
                        <span className="input-group-btn">
                          <button type="reset" className="btn btn-default">
                            <i className="fas fa-times" />
                          </button>
                          <button
                            type="submit"
                            className="btn btn-default nav-link resp-btn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              className="header-icon-svgs"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                            </svg>
                          </button>
                        </span>
                      </div>
                    </form>
                  </li>
                  <li className="dropdown main-profile-menu nav nav-item nav-link ps-lg-2">
                    <a
                      className="new nav-link profile-user d-flex"
href="#"
                      data-bs-toggle="dropdown"
                    >
                      <img
                        alt="User profile logo"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/placeholderLogo.png';
                        }}
                        src={`${
                          user?.profilePicture?.path
                            ? BASE_PATH + '/' + user?.profilePicture?.path
                            : '/images/placeholderLogo.png'
                        }`}
                        className="object-fit-cover"
                      />
                    </a>
                    <div className="dropdown-menu">
                      <div className="menu-header-content p-3 border-bottom">
                        <div className="d-flex wd-100p">
                          <div className="main-img-user">

                            <img
                              alt="User profile logo"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                  '/images/placeholderLogo.png';
                              }}
                              src={`${
                                user?.profilePicture?.path
                                  ? BASE_PATH + '/' + user?.profilePicture?.path
                                  : '/images/placeholderLogo.png'
                              }`}
                              className="object-fit-cover"
                            />
                          </div>
                          <div className="ms-3 my-auto">
                            <h6 className="tx-15 font-weight-semibold mb-0">
                              {user.firstName} {user.lastName}
                            </h6>
                            <span className="dropdown-title-text subtext op-6  tx-12 word-break">
                              {user?.roles}
                            </span>
                          </div>
                        </div>
                      </div>
                      <Link className="dropdown-item" to="/profile/myProfile">
                        <i className="far fa-user-circle" />
                        Profile
                      </Link>
                      <Link className="dropdown-item" onClick={logout} to="/">
                        <i className="far fa-arrow-alt-circle-left" /> Sign Out
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
