import React from 'react';
import { Pagination } from '../../../shared/paginations/Paginations';
import { useTender } from '../../../shared/hooks/app/useTender';
import {
  addCommissioner,
  getCommissioner,
  getCommissioners,
  putCommissioner,
} from '../Commissioner.service';
import { CommissionerCreate } from './CommissionerCreate';
import { CommissionerTable } from './CommissionerTable';
import { CommissionerUpdateModal } from './CommissionerUpdateModal';

export function CommissionerContent() {
  const {
    page,
    handlePages,
    totalPages,
    listOfAllData,
    updateData,
    createData,
    getCurrentDataID,
    changeHandler,
    showForm,
    filterData,
    setShowForm,
    selectedData,
  } = useTender({
    getData: getCommissioner,
    putData: putCommissioner,
    getMany: getCommissioners,
    addData: addCommissioner,
  });

  return (
    <>
      <div className="row row-sm">
        <CommissionerCreate
          showForm={showForm}
          setShowForm={setShowForm}
          createData={createData}
          changeHandler={changeHandler}
        />
      </div>

      <div className="row">
        <CommissionerTable
          listOfAllData={listOfAllData}
          filterData={filterData}
          getCurrentDataID={getCurrentDataID}
        />
      </div>
      <div className="w-100 d-flex align-items-center justify-content-end">
        {totalPages <= 1 ? (
          ''
        ) : (
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePagination={handlePages}
          />
        )}
      </div>
      <CommissionerUpdateModal
        updateData={updateData}
        selectedData={selectedData!}
        changeHandler={changeHandler}
      />
    </>
  );
}
