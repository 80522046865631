import {requests} from "../../utils/helpers/api.services";
import {BASE_PATH} from "../../utils/helpers/api.routes";
import {ErrorToast} from "../../utils/toasters/toasters";

export interface iForgotPasswordRequest {
    email: string
    password_repeat?: string
    password?: string
    code?: string
}

export function ForgotPasswordRequest(body: iForgotPasswordRequest) {
    return requests.post(`${BASE_PATH}/forgot-password-request`, body)
        .then(res => res)
        .catch(error => ErrorToast(error));
}

export function ForgotPasswordChange(body:iForgotPasswordRequest) {
    return requests.post(`${BASE_PATH}/forgot-password-change`, body)
        .then(res=>res)
        .catch(error=>ErrorToast(error));
}