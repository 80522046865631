import {iCrud, iGetData} from "../../model/app/Application";
import {requests} from "../../utils/helpers/api.services";
import {BASE_PATH} from "../../utils/helpers/api.routes";
import {ErrorToast} from "../../utils/toasters/toasters";

export function addCompanies(body: any) {
    return requests.post(`${BASE_PATH}/admin/companies`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function deleteCompanyDocument(id: number) {
    return requests.delete(`${BASE_PATH}/admin/companies/document/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error))
}

export function getCompany(id: number) {
    return requests.get(`${BASE_PATH}/admin/companies/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function putCompanies(id: number, body?: iCrud) {
    return requests.put(`${BASE_PATH}/admin/companies/${id}`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getCompanies(body?: iGetData) {
    return requests.get(`${BASE_PATH}/admin/companies`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function deleteCompany(id: number) {
    return requests.delete(`${BASE_PATH}/admin/companies/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getPossibleSupervisors(id: number) {
    return requests.get(`${BASE_PATH}/admin/companies/possible-supervisors/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error));
}