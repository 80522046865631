import React, { SetStateAction, useEffect, useState } from 'react';
import { iCrud, iSelectedModalData } from '../../../model/app/Application';
import { useTenderSelect } from '../../../shared/hooks/app/useTenderSelect';
import { themeSelect } from './TenderFiltering';
import Select from 'react-select';
import { putTenders } from '../Tender.service';
import { SuccessToast } from '../../../utils/toasters/toasters';
import { getPossibleSupervisors } from '../../companies/Companies.service';
import { useTranslation } from 'react-i18next';

interface iTenderCreate {
  storedCompanyID: any;
  commissionerList: any;
  locationList: any;
  setShowEditTender?: React.Dispatch<SetStateAction<boolean>>;
  clientList: any;
  selectedData: any;
  typeList: any;
  companyList: any;
  setTriggerRerender: React.Dispatch<SetStateAction<boolean>>;
  triggerRerender: boolean;
}

export const TenderUpdate = ({
  storedCompanyID,
  commissionerList,
  locationList,
  setShowEditTender,
  clientList,
  companyList,
  selectedData,
  triggerRerender,
  setTriggerRerender,
  typeList,
}: iTenderCreate) => {
  const {
    optionsCommissioner,
    optionsTenderType,
    optionsLocation,
    optionsCompany,
    optionsClient,
    tempCommissionerArray,
    tempClientArray,
    tempLocationArray,
    tempTypeArray,
  } = useTenderSelect({
    commissionerList,
    locationList,
    clientList,
    companyList,
    typeList,
    selectedData,
  });
  const { t } = useTranslation();
  const [dataToSend, setDataToSend] = useState<iCrud>();

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    if (event.target.name === 'maxRevenue') {
      setDataToSend((prev: iCrud | undefined) => ({
        ...prev,
        [event.target.name]: +event.target.value,
      }));
    } else {
      setDataToSend((prev: iCrud | undefined) => ({
        ...prev,
        [event.target.name]:
          event.target.name === 'isProlongation'
            ? (event?.target as HTMLInputElement).checked
            : event?.target?.value,
      }));
    }
  };

  const changeSelectHandler = (event: any) => {
    if (event?.isCompany) {
      localStorage.setItem('selectedCompanyID', event?.value);
      setDataToSend((prev) => ({ ...prev, company: event?.value }));
    } else if (event?.isClient) {
      setDataToSend((prev) => ({ ...prev, client: event?.value }));
    } else if (event?.isCommissioner) {
      setDataToSend((prev) => ({ ...prev, commissioner: event?.value }));
    } else if (event?.isLocation) {
      setDataToSend((prev) => ({ ...prev, location: event?.value }));
    } else if (event?.isTenderType) {
      setDataToSend((prev) => ({ ...prev, tenderType: event?.value }));
    } else if (event?.isSupervisor) {
      setDataToSend((prev) => ({ ...prev, supervisor: event?.value }));
    }
  };

  const updateTender = () => {
    putTenders(selectedData?.tender?.id, {
      ...dataToSend,
      supervisor: dataToSend?.supervisor
        ? dataToSend?.supervisor
        : selectedData?.tender?.supervisor?.id,
      commissioner: dataToSend?.commissioner
        ? dataToSend?.commissioner
        : selectedData?.tender?.commissioner?.id,
      client: dataToSend?.client
        ? dataToSend?.client
        : selectedData?.tender?.client?.id,
      location: dataToSend?.location
        ? dataToSend?.location
        : selectedData?.tender?.location?.id,
      tenderType: dataToSend?.tenderType
        ? dataToSend?.tenderType
        : selectedData?.tender?.tenderType?.id,
      title: dataToSend?.title
        ? dataToSend?.title
        : selectedData?.tender?.title,
    })
      .then((res) => {
        if (res) {
          SuccessToast('You have successfully updated Tender');
          setTriggerRerender(!triggerRerender);
          setSelectedCompanyID(0);
        }
      })
      .catch((error) => error);
  };

  const [selectedCompanyID, setSelectedCompanyID] = useState(0);
  const [possibleSupervisors, setPossibleSupervisors] = useState([]);

  const changeCompanyHandler = (event: any) => {
    setSelectedCompanyID(event?.value);
  };

  let optionsSupervisor = possibleSupervisors?.map(
    (supervisor: iSelectedModalData) => {
      return {
        value: supervisor?.id,
        label: `${supervisor?.firstName} ${supervisor?.lastName}`,
        isSupervisor: true,
      };
    }
  );

  useEffect(() => {
    selectedCompanyID !== 0 &&
      getPossibleSupervisors(+selectedCompanyID!)
        .then((res) => {
          res && setPossibleSupervisors(res);
        })
        .catch((error) => error);
  }, [JSON.stringify(selectedCompanyID)]);

  return (
    <>
      <div className="card-body pt-0">
        <div className="form-group mb-0">
          <div className="row">
            <div className="col-md-3 mt-3">
              <label htmlFor="title">{t('Tenders.title')}</label>
              <input
                type="text"
                className="form-control updateModalField"
                name="title"
                defaultValue={selectedData?.tender?.title}
                key={selectedData?.tender?.title}
                onBlur={changeHandler}
                placeholder="Enter title"
              />
            </div>
            <div className="col-md-3 mt-3">
              <label htmlFor="commissioner">{t('Tenders.commissioner')}</label>

              <Select
                options={optionsCommissioner}
                onChange={changeSelectHandler}
                id="custom-select2"
                defaultValue={tempCommissionerArray}
                theme={themeSelect}
              />
            </div>
            <div className="col-md-3 mt-3">
              <label htmlFor="location">{t('Tenders.location')}</label>
              <Select
                options={optionsLocation}
                onChange={changeSelectHandler}
                id="custom-select2"
                defaultValue={tempLocationArray}
                theme={themeSelect}
              />
            </div>
            <div className="col-md-3 mt-3">
              <label htmlFor="type">{t('Tenders.tender_type')}</label>
              <Select
                options={optionsTenderType}
                onChange={changeSelectHandler}
                id="custom-select2"
                defaultValue={tempTypeArray}
                theme={themeSelect}
              />
            </div>
            <div className="col-md-8 mt-3">
              <label htmlFor="description">{t('Tenders.description')}</label>
              <textarea
                className="form-control updateModalField"
                name="description"
                rows={7}
                defaultValue={selectedData?.tender?.description}
                key={selectedData?.tender?.description}
                onBlur={changeHandler}
                placeholder="Enter description"
              />
            </div>
            <div className="col-md-4">
              <div className="w-100 mt-3">
                <label htmlFor="maxRevenue">{t('Tenders.max_revenue')}</label>
                <input
                  type="number"
                  className="form-control updateModalField"
                  name="maxRevenue"
                  onBlur={changeHandler}
                  defaultValue={selectedData?.tender?.maxRevenue}
                  key={selectedData?.tender?.maxRevenue}
                />
              </div>
              <div className="w-100 mt-3">
                <label htmlFor="applicationDeadline">
                  {t('Tenders.deadline')}
                </label>
                <input
                  type="date"
                  className="form-control updateModalField"
                  name="applicationDeadline"
                  onBlur={changeHandler}
                  defaultValue={selectedData?.tender?.applicationDeadline}
                  key={selectedData?.tender?.applicationDeadline}
                />
              </div>

              <div className="w-100 mt-3">
                <label htmlFor="dateOfTenderAwardance">
                  {t('Tenders.awardance_date')}
                </label>
                <input
                  type="date"
                  className="form-control updateModalField"
                  name="dateOfTenderAwardance"
                  onBlur={changeHandler}
                  defaultValue={selectedData?.tender?.dateOfTenderAwardance}
                  key={selectedData?.tender?.dateOfTenderAwardance}
                />
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="source">{t('Tenders.source')}</label>
              <input
                type="text"
                className="form-control updateModalField"
                name="source"
                onBlur={changeHandler}
                defaultValue={selectedData?.tender?.source}
                key={selectedData?.tender?.source}
                placeholder="Enter source"
              />
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="link">{t('Tenders.link')}</label>
              <input
                type="text"
                className="form-control updateModalField"
                name="link"
                onBlur={changeHandler}
                defaultValue={selectedData?.tender?.link}
                key={selectedData?.tender?.link}
                placeholder="Enter link"
              />
            </div>
            <div className="col-md-2 mt-3">
              <label htmlFor="startDate">{t('Tenders.start_date')}</label>
              <input
                type="date"
                className="form-control"
                name="startDate"
                onChange={changeHandler}
                defaultValue={selectedData?.tender?.startDate?.substr(0, 10)}
                key={selectedData?.tender?.startDate}
                placeholder="Enter startDate"
              />
            </div>
            <div className="col-md-2 mt-3">
              <label htmlFor="endDate">{t('Tenders.end_date')}</label>
              <input
                type="date"
                className="form-control"
                name="endDate"
                onChange={changeHandler}
                defaultValue={selectedData?.tender?.endDate?.substr(0, 10)}
                key={selectedData?.tender?.endDate}
                placeholder="Enter endDate"
              />
            </div>
            <div className="col-md-2 mt-3">
              <label htmlFor="los">{t('Tenders.los')}</label>
              <input
                type="text"
                className="form-control"
                name="loss"
                onChange={changeHandler}
                defaultValue={selectedData?.tender?.loss}
                key={selectedData?.tender?.loss}
                placeholder="Enter los"
              />
            </div>
            <div className="col-md-1 mt-3 d-flex flex-column">
              <label htmlFor="isProlongation">
                {t('Tenders.prolongation')}
              </label>
              <label className="switch">
                <input
                  type="checkbox"
                  name="isProlongation"
                  onChange={changeHandler}
                  defaultChecked={selectedData?.tender?.isProlongation}
                  key={selectedData?.tender?.isProlongation}
                />
                <span className="slider round" />
              </label>
            </div>
            <div className="col-md-3 mt-3">
              <label htmlFor="personalRequired">
                {t('Tenders.personal_required')}
              </label>
              <input
                type="text"
                className="form-control"
                name="personalRequired"
                onChange={changeHandler}
                defaultValue={selectedData?.tender?.personalRequired}
                key={selectedData?.tender?.personalRequired}
                placeholder="Enter Personal required"
              />
            </div>

            <div className="col-md-2 mt-3">
              <label htmlFor="spaseRequired">
                {' '}
                {t('Tenders.space')} / {t('Tenders.room_required')}
              </label>
              <input
                type="text"
                className="form-control"
                name="spaseRequired"
                onChange={changeHandler}
                defaultValue={selectedData?.tender?.spaseRequired}
                key={selectedData?.tender?.spaseRequired}
                placeholder="Enter Spase required"
              />
            </div>

            <div className="col-md-2 mt-3">
              <label htmlFor="spaseRequired">
                {t('Tenders.technical_required')}
              </label>
              <input
                type="text"
                className="form-control"
                name="technicalRequirements"
                onChange={changeHandler}
                defaultValue={selectedData?.tender?.technicalRequirements}
                key={selectedData?.tender?.technicalRequirements}
                placeholder="Enter technical requirements"
              />
            </div>
            <div className="col-12 mt-5">
              <h5>{t('Tenders.relevance_check_employee')}</h5>
            </div>
            {/* <div className="col-md-6 mt-2">
                            <label htmlFor="client">Client</label>
                            <Select options={optionsClient}
                                    onChange={changeSelectHandler}
                                    id="custom-select2"
                                    defaultValue={tempClientArray}
                                    theme={themeSelect}/>
                        </div> */}
            <div className="col-md-3 mt-2">
              <label htmlFor="company">{t('Tenders.select_company')}</label>
              {selectedData?.tender?.supervisor?.company?.title && (
                <Select
                  options={optionsCompany}
                  onChange={changeCompanyHandler}
                  id="custom-select2"
                  key={selectedData?.tender?.supervisor?.company?.id}
                  defaultValue={{
                    value: selectedData?.tender?.supervisor?.company?.id,
                    label: `${selectedData?.tender?.supervisor?.company?.title}`,
                  }}
                  theme={themeSelect}
                />
              )}
            </div>
            {storedCompanyID !== null && (
              <div className="col-md-3 mt-2">
                <label htmlFor="supervisor">
                  {t('Tenders.select_supervisor')}
                </label>
                <Select
                  options={optionsSupervisor}
                  onChange={changeSelectHandler}
                  key={selectedData?.tender?.supervisor?.company?.id}
                  defaultValue={{
                    value: selectedData?.tender?.supervisor?.id,
                    label: `${selectedData?.tender?.supervisor?.firstName} ${selectedData?.tender?.supervisor?.lastName}`,
                  }}
                  id="custom-select2"
                  theme={themeSelect}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row w-100 pb-4">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-secondary "
            onClick={() => {
              setShowEditTender!(false);
              localStorage.removeItem('selectedCompanyID');
            }}
          >
            {t('Tenders.close_editing')}
          </button>

          <button
            className="btn btn-primary ms-2"
            key={selectedData?.tender?.id}
            onClick={() => {
              setShowEditTender!(false);
              localStorage.removeItem('selectedCompanyID');
              updateTender();
            }}
          >
            {t('Tenders.save_changes')}
          </button>
        </div>
      </div>
    </>
  );
};
