import React from 'react';
import { iDataCreate } from '../../../model/app/Application';
import { useTranslation } from 'react-i18next';
export const CancellationCreate = ({
  showForm,
  setShowForm,
  createData,
  changeHandler,
}: iDataCreate) => {
  const { t } = useTranslation();
  return (
    <div className="col-12">
      <div className="card">
        <div
          className={`card-header pb-0 d-flex justify-content-between align-items-center flex-column flex-md-row ${
            !showForm && 'pb-4'
          }`}
        >
          <div>
            <div className="d-flex justify-content-between">
              <h4 className="card-title mg-b-0">{t('Cancellation.create_new_cancellation')}</h4>
            </div>
            <p className="tx-12 tx-gray-500 mb-2">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </p>
          </div>
          {!showForm && (
            <button
              className="btn btn-primary"
              onClick={() => setShowForm(!showForm)}
            >
              {t('Cancellation.add_cancellation')}
            </button>
          )}
        </div>
        {showForm && (
          <div className="card-body">
            <div className="form-group mb-0">
              <label htmlFor="exampleInputEmail1">{t('Cancellation.reason')}</label>
              <input
                type="text"
                className="form-control"
                name="reason"
                onChange={changeHandler}
                placeholder={t('Cancellation.enter_reason')}
              />
            </div>
            <div className="d-flex justify-content-end w-100">
              <button
                onClick={createData}
                className="btn btn-primary mt-3 mb-0"
              >
                {t('Cancellation.create_cancellation')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
