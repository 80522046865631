import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { SignIn } from './LoginPage.service';
import { SuccessToast, WarningToast } from '../../utils/toasters/toasters';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export function LoginPage() {
  const navigate = useNavigate();
  const [visibilityPassword, setVisibility] = useState(false);
  const [loginState, setLoginState] = useState({
    email: '',
    password: '',
  });

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const sendData = () => {
    SignIn(loginState)
      .then((res) => {
        if (res !== undefined) {
          if (res.user.roles.includes('ROLE_SUPER_ADMIN')) {
            localStorage.setItem('token', res.token);
            localStorage.setItem(
              'currentUser',
              JSON.stringify({
                user: {
                  company: res.user.company,
                  email: res.user.email,
                  firstName: res.user.firstName,
                  lastName: res.user.lastName,
                  roles: res.user.roles,
                  profilePicture: res.user.profilePicture,
                },
              })
            );
            navigate('/main/dashboard');
            SuccessToast('You are successfully logged in!');
          } else {
            WarningToast('You are not Super Admin!');
          }
        }
      })
      .catch((error) => error);
  };

  const loginPressingEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') return sendData();
  };

  return (
    <div className="bg-primary">
      <div className="page">
        <div className="page-single ">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main mx-auto my-auto py-45 justify-content-center">
                <div className="card-sigin mt-5 mt-md-0">
                  <div className="main-card-signin d-md-flex">
                    <div className="wd-100p">
                      <div className="d-flex mb-4 justify-content-center">
                        <img
                          src="/images/bbi-logo-blue.png"
                          className="sign-favicon ht-40"
                          alt="logo"
                        />
                      </div>
                      <div className="">
                        <div className="main-signup-header">
                          <h2 className="text-center">Welcome back!</h2>
                          <h6 className="font-weight-semibold mb-4 text-center">
                            Please sign in to continue.
                          </h6>
                          <div className="panel panel-primary">
                            <div className="panel-body tabs-menu-body border-0 p-3">
                              <div className="tab-content">
                                <div className="tab-pane active" id="tab5">
                                  <div className="form-group">
                                    <label>Email</label>
                                    <input
                                      name="email"
                                      onChange={changeHandler}
                                      className="form-control"
                                      id="emailInputField"
                                      placeholder="Enter your email"
                                      type="text"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="password-input"
                                    >
                                      Password
                                    </label>
                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                      <input
                                        name="password"
                                        onChange={changeHandler}
                                        type={`${
                                          visibilityPassword
                                            ? 'text'
                                            : 'password'
                                        }`}
                                        className="form-control pe-5"
                                        placeholder="Enter password"
                                        id="password-input"
                                        onKeyUp={loginPressingEnter}
                                      />
                                      <button
                                        className="btn position-absolute pw-btn text-decoration-none text-muted"
                                        type="button"
                                        id="password-addon"
                                        onClick={() =>
                                          setVisibility(!visibilityPassword)
                                        }
                                      >
                                        {!visibilityPassword ? (
                                          <VisibilityOffIcon
                                            onClick={() => setVisibility(false)}
                                            className="text-gray ms--2 cursor-pointer"
                                          />
                                        ) : (
                                          <VisibilityIcon
                                            onClick={() => setVisibility(true)}
                                            className="text-gray ms--2 cursor-pointer"
                                          />
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  <button
                                    onClick={sendData}
                                    className="btn btn-primary btn-block"
                                  >
                                    Sign In
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="main-signin-footer text-center mt-3">
                            <p>
                              <Link
                                to="/forgot"
                                state={{ isLoginPrevious: true }}
                                className="mb-3"
                              >
                                Forgot password?
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
