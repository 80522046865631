import {Link, useLocation} from "react-router-dom";

export function NotFound() {
    const location = useLocation();
    return (
        <div className="bg-primary">
            <div className="main-error-wrapper page page-h">
                <h1 className="text-white">404<span className="tx-20">error</span></h1>
                <h2 className="text-white">Oopps. The page you were looking for doesn't exist.</h2>
                <h6 className="tx-white-6">You may have mistyped the address or the page may have moved.</h6><Link
                className="btn btn-light" to={(location?.state as any).isLoginPrevious ? '/' : '/main/dashboard'}>Back
                to Home</Link>
            </div>
        </div>
    )
}