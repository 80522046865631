import {iCrud, iGetData} from "../../model/app/Application";
import {requests} from "../../utils/helpers/api.services";
import {BASE_PATH} from "../../utils/helpers/api.routes";
import {ErrorToast} from "../../utils/toasters/toasters";

export function addCommissioner(body: iCrud) {
    return requests.post(`${BASE_PATH}/admin/commissioners`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getCommissioner(id: number) {
    return requests.get(`${BASE_PATH}/admin/commissioners/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function putCommissioner(id: number, body?: iCrud) {
    return requests.put(`${BASE_PATH}/admin/commissioners/${id}`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getCommissioners(body?: iGetData) {
    return requests.get(`${BASE_PATH}/admin/commissioners`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}