import { useEffect, useState } from 'react';
import { getUsers } from '../../../app/users/Users.service';
import { getCommissioners } from '../../../app/commissioner/Commissioner.service';

import { getCompanies } from '../../../app/companies/Companies.service';
import { getTenders } from '../../../app/tenders/Tender.service';
import { getLocations } from '../../../app/location/Location.service';
import { getTypes } from '../../../app/type/Type.service';
import { getIrrelevances } from '../../../app/Irrelevance/Irrelevance.service';
import { getCancellations } from '../../../app/cancellation/Cancellation.service';
import { getLosses } from '../../../app/loss/Loss.service';

export function useDashboard() {
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [numberOfCommissioners, setNumberOfCommissioners] = useState(0);
  const [numberOfClients, setNumberOfClients] = useState(0);
  const [numberOfCompanies, setNumberOfCompanies] = useState(0);
  const [numberOfTenders, setNumberOfTenders] = useState(0);
  const [numberOfLocations, setNumberOfLocations] = useState(0);
  const [numberOfTypes, setNumberOfTypes] = useState(0);
  const [numberOfIrrelevances, setNumberOfIrrelevances] = useState(0);
  const [numberOfCancellations, setNumberOfCancellations] = useState(0);
  const [numberOfLosses, setNumberOfLosses] = useState(0);

  useEffect(() => {
    getUsers()
      .then((res) => {
        res && setNumberOfUsers(res.count);
      })
      .catch((error) => error);

    getCommissioners()
      .then((res) => {
        res && setNumberOfCommissioners(res.count);
      })
      .catch((error) => error);

    getCompanies()
      .then((res) => {
        res && setNumberOfCompanies(res.count);
      })
      .catch((error) => error);

    getTenders()
      .then((res) => {
        res && setNumberOfTenders(res.count);
      })
      .catch((error) => error);

    getLocations()
      .then((res) => {
        res && setNumberOfLocations(res.count);
      })
      .catch((error) => error);

    getTypes()
      .then((res) => {
        res && setNumberOfTypes(res.count);
      })
      .catch((error) => error);

    getIrrelevances()
      .then((res) => {
        res && setNumberOfIrrelevances(res.count);
      })
      .catch((error) => error);

    getCancellations()
      .then((res) => {
        res && setNumberOfCancellations(res.count);
      })
      .catch((error) => error);

    getLosses()
      .then((res) => {
        res && setNumberOfLosses(res.count);
      })
      .catch((error) => error);
  }, []);

  return {
    numberOfUsers,
    numberOfCommissioners,
    numberOfClients,
    numberOfCompanies,
    numberOfTenders,
    numberOfLocations,
    numberOfTypes,
    numberOfIrrelevances,
    numberOfCancellations,
    numberOfLosses,
  };
}
