import Select from 'react-select';
import { themeSelect } from '../../tenders/components/TenderFiltering';
import React, { SetStateAction, useRef, useState } from 'react';
import { iTempSelectArray } from '../../../model/app/Application';
import { multipartFormData } from '../../../utils/helpers/api.services';
import { BASE_PATH } from '../../../utils/helpers/api.routes';
import { ErrorToast, SuccessToast } from '../../../utils/toasters/toasters';
import { addUser } from '../Users.service';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';

interface iUserCreateProps {
  showForm: boolean;
  setShowForm: React.Dispatch<SetStateAction<boolean>>;
  changeHandler?: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => void;
  optionsCompany: iTempSelectArray[];
  optionsRole: iTempSelectArray[];
  createData?: () => void;
  changeSelectHandler?: (event: any) => void;
  triggerRerender: boolean;
  setTriggerRerender: React.Dispatch<SetStateAction<boolean>>;
}

export const UserCreate = ({
  showForm,
  setShowForm,
  optionsCompany,
  triggerRerender,
  setTriggerRerender,
  optionsRole,
}: iUserCreateProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<any>(null);

  const [showPassword, setShowPassword] = useState(true);
  const [dataState, setDataState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    company: 0,
    roles: [],
  });

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    switch (event.target.name) {
      case 'maxRevenue':
      case 'client':
      case 'commissioner':
        setDataState((prev) => ({
          ...prev,
          [event.target.name]: +event.target.value,
        }));
        break;
      default:
        setDataState((prev) => ({
          ...prev,
          [event.target.name]: event.target.value,
        }));
    }
  };

  const changeSelectHandler = (event: any) => {
    if (event?.isCompany) {
      localStorage.setItem('selectedCompanyID', event?.value);
      setDataState((prev) => ({ ...prev, company: event?.value }));
    } else if (event?.isClient) {
      setDataState((prev) => ({ ...prev, client: +event?.value }));
    } else if (event?.isCommissioner) {
      setDataState((prev) => ({ ...prev, commissioner: +event?.value }));
    } else if (event?.isLocation) {
      setDataState((prev) => ({ ...prev, location: event?.value }));
    } else if (event?.isTenderType) {
      setDataState((prev) => ({ ...prev, tenderType: event?.value }));
    } else if (event?.isSupervisor) {
      setDataState((prev) => ({ ...prev, supervisor: event?.value }));
    } else if (event[0]?.isRole) {
      setDataState((prev) => ({
        ...prev,
        roles: event?.map((role: any) => role.value),
      }));
    }
  };

  const createUser = () => {
    const modalData = new FormData();
    if (inputRef?.current?.files?.length) {
      for (let file in inputRef.current.files) {
        modalData.append('file', inputRef.current.files[file]);
      }
    }
    modalData.append('body', JSON.stringify(dataState));
    addUser(modalData)
      .then((res) => {
        if (res) {
          SuccessToast('User successfully created');
          setTriggerRerender(!triggerRerender);
          setShowForm(false);
        }
      })
      .catch((error) => error);
  };

  return (
    <div className="card">
      <div
        className={`card-header pb-0 d-flex justify-content-between align-items-center flex-column flex-md-row ${
          !showForm && 'pb-4'
        }`}
      >
        <div>
          <div className="d-flex justify-content-between">
            <h4 className="card-title mg-b-0">{t('Users.create_new_user')}</h4>
          </div>
          <p className="tx-12 tx-gray-500 mb-2">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </p>
        </div>
        {!showForm && (
          <button
            className="btn btn-primary"
            onClick={() => setShowForm(!showForm)}
          >
            {t('Users.add_user')}
          </button>
        )}
      </div>
      {showForm && (
        <div className="card-body">
          <div className="form-group mb-0">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="firstName">
                  <span className="text-danger">*</span>
                  {t('Users.first_name')}
                </label>
                <input
                  className="form-control"
                  name="firstName"
                  onChange={changeHandler}
                  placeholder={t('Users.enter_first_name')}
                  type="text"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="lastName">{t('Users.last_name')}</label>
                <input
                  className="form-control"
                  name="lastName"
                  onChange={changeHandler}
                  placeholder={t('Users.enter_last_name')}
                  type="text"
                />
              </div>
              <div className="col-md-6 mt-md-3">
                <label htmlFor="email">
                  <span className="text-danger">*</span>
                  {t('Users.email')}
                </label>
                <input
                  className="form-control"
                  name="email"
                  autoComplete="new-email"
                  onChange={changeHandler}
                  placeholder={t('Users.enter_email')}
                  type="text"
                />
              </div>
              <div className="col-md-6 mt-md-3">
                <label htmlFor="password">
                  <span className="text-danger">*</span>
                  {t('Users.password')}
                </label>
                <div className="d-flex align-items-center">
                  <input
                    type={showPassword ? t('Users.password') : t('Users.text')}
                    className="form-control"
                    name="password"
                    autoComplete="new-password"
                    onChange={changeHandler}
                    placeholder={t('Users.enter_password')}
                  />
                  {showPassword ? (
                    <VisibilityOffIcon
                      onClick={() => setShowPassword(false)}
                      className="text-gray ms--2 cursor-pointer"
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={() => setShowPassword(true)}
                      className="text-gray ms--2 cursor-pointer"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-4 mt-md-3">
                <label htmlFor="company">
                  <span className="text-danger">*</span>
                  {t('Users.select_company')}
                </label>
                <Select
                  options={optionsCompany}
                  onChange={changeSelectHandler}
                  id="custom-select2"
                  theme={themeSelect}
                />
              </div>

              <div className="col-md-4 mt-md-3">
                <label htmlFor="roles">
                  <span className="text-danger">*</span>
                  {t('Users.roles')}
                </label>
                <Select
                  options={optionsRole}
                  onChange={changeSelectHandler}
                  id="custom-select2"
                  isMulti={true}
                  isClearable={true}
                  theme={themeSelect}
                />
              </div>

              <div className="col-md-4 mt-md-3">
                <label htmlFor="file">
                  {t('Users.upload_profile_picture')}
                </label>
                <div className="form-control d-flex align-items-center justify-content-start px-0">
                  <input
                    type="file"
                    onChange={changeHandler}
                    name="file"
                    ref={inputRef}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end w-100">
            <button onClick={createUser} className="btn btn-primary mt-3 mb-0">
              {t('Users.create_user')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
