import {formatDate} from "../../../shared/functions/Functions";
import {Modals} from "../../../shared/modals/Modals";
import React from "react";
import {iDataDeleteModal} from "../../../model/app/Application";
import { useTranslation } from 'react-i18next';
export const IrrelevanceDeleteModal = ({removeData, selectedData}: iDataDeleteModal) => {
    const { t } = useTranslation()
    return (
        <Modals modalId="deleteModal" buttonType="btn-secondary"
                title="Are you sure you want to delete this item?" saveButtonName="Delete"
                updateChanges={removeData} body={
            <div>
                <div>
                    <label htmlFor="id" className="font-weight-bold">ID:</label>
                    <span className="ms-1">{selectedData?.id}</span>
                </div>
                <div>
                    <label htmlFor="reason" className="font-weight-bold">{t('Irrelevance.reason')}:</label>
                    <span className="ms-1 ">{selectedData?.reason}</span>
                </div>
                <div>
                    <label htmlFor="createdAt" className="font-weight-bold">{t('Irrelevance.created_at')}:</label>
                    <span className="ms-1 ">{formatDate(selectedData!?.createdAt as string)}</span>
                </div>
                <div>
                    <label htmlFor="updatedAt" className="font-weight-bold">{t('Irrelevance.updated_at')}:</label>
                    <span className="ms-1 ">{formatDate(selectedData!?.updatedAt as string)}</span>
                </div>
            </div>
        }/>
    )
}