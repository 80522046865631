import React from 'react';
import { iResponseCrud, iTable } from '../../../model/app/Application';
import { useTranslation } from 'react-i18next';
export const TenderTable = ({
  listOfAllData,
  getCurrentDataID,
  setShowForm,
  scrollIntoView,
  setShowEditTender,
  deleteTender,
  isDeletedList,
}: iTable) => {
  const { t } = useTranslation();
  return (
    <div className="card-body px-0">
      <div className="table-responsive">
        <table className="table table-bordered table-hover mb-0 text-md-nowrap">
          <thead>
            <tr>
              <th>ID</th>
              <th>{t('Tenders.title')}</th>
              <th>{t('Tenders.companies')}</th>
              <th>{t('Tenders.relevance')}</th>
              <th>{t('Tenders.submission')}</th>
              <th>
                {t('Tenders.won')} / {t('Tenders.lost')}
              </th>
              <th>{t('Tenders.deadline')}</th>
              <th>{t('Tenders.change')}</th>
            </tr>
          </thead>
          <tbody>
            {listOfAllData!?.length > 0 ? (
              listOfAllData?.map((data: iResponseCrud) => {
                console.log(data.isWon);
                return (
                  <tr key={data?.id}>
                    <td>{data?.id}</td>
                    <td>{data?.title ? data?.title : '-'}</td>
                    <td>
                      {data?.bids!?.length > 0
                        ? data?.bids?.map((company) => company?.company?.title)
                        : '-'}
                    </td>
                    <td>
                      {data?.isRelevant
                        ? 'Yes'
                        : data?.isRelevant === null
                        ? null
                        : 'No'}
                    </td>
                    <td>
                      {data?.isSubmitted
                        ? 'Yes'
                        : data?.isSubmitted === null
                        ? null
                        : 'No'}
                    </td>
                    <td>
                      {data?.isWon
                        ? 'Won'
                        : data?.isWon === null
                        ? null
                        : 'Lost'}
                    </td>
                    <td>
                      {data?.applicationDeadline
                        ? data?.applicationDeadline
                        : '-'}
                    </td>
                    <td className="d-flex justify-content-center">
                      <button
                        className="btn btn-secondary ripple me-1"
                        onClick={() => deleteTender(+data?.id)}
                      >
                        {!isDeletedList
                          ? t('Tenders.delete')
                          : t('Tenders.undo')}
                      </button>
                      <button
                        className="btn btn-primary ripple"
                        onClick={() => {
                          getCurrentDataID!(data.id);
                          setShowForm!(false);
                          scrollIntoView!();
                          setShowEditTender!(true);
                        }}
                      >
                        {t('Tenders.change')}
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
