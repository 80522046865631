import { iResponseCrud, iTable } from '../../../model/app/Application';
import { formatDate } from '../../../shared/functions/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export const UserTable = ({
  listOfAllData,
  getCurrentDataID,
  toggleUserStatus,
  handleShow,
  setCurrentUser,
}: iTable) => {
  const { t } = useTranslation();

  return (
    <div className="card-body">
      <div className="table-responsive">
        <table className="table table-bordered table-hover mb-0 text-md-nowrap">
          <thead>
            <tr>
              <th>ID</th>
              <th>{t('Users.first_name')}</th>
              <th>{t('Users.last_name')}</th>
              <th>{t('Users.email')}</th>
              <th>{t('Users.roles')}</th>
              <th>{t('Users.created_at')}</th>
              <th>{t('Users.updated_at')}</th>
              <th>{t('Users.actions')}</th>
              <th>{t('Users.change_password')}</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {listOfAllData!?.length > 0 ? (
              listOfAllData?.map((data: iResponseCrud) => {
                console.log(data);
                return (
                  <tr key={data.id}>
                    <th scope="row">{data?.id}</th>
                    <td>{data?.firstName ? data?.firstName : '-'}</td>
                    <td>{data?.lastName ? data?.lastName : '-'}</td>
                    <td>{data?.email ? data?.email : '-'}</td>
                    <td>
                      {data?.roles?.map(
                        (role: any) => `${role.split('_').join(' ')}, `
                      )}
                    </td>
                    <td>{formatDate(data?.createdAt as string)}</td>
                    <td>{formatDate(data?.updatedAt as string)}</td>
                    <td>
                      <button
                        className="btn btn-primary ripple"
                        onClick={() => getCurrentDataID!(data.id)}
                        data-bs-target="#modaldemo1"
                        data-bs-toggle="modal"
                      >
                        {t('Users.change')}
                      </button>
                    </td>
                    <td className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary ripple"
                        onClick={() => getCurrentDataID!(data.id)}
                        data-bs-target="#changePasswordModal"
                        data-bs-toggle="modal"
                      >
                        {t('Users.change_password')}
                      </button>
                    </td>
                    <td>
                      <div className="d-flex">
                        <button
                          onClick={() => toggleUserStatus(data.id)}
                          className={`btn ${
                            data.isActive ? 'btn-secondary' : 'btn-primary'
                          } ripple me-1`}
                        >
                          {data?.isActive ? 'deactivate' : 'activate'}
                        </button>
                        {!data?.isActive && (
                          <button
                            className="btn btn-secondary ripple me-1"
                            onClick={() => {
                              handleShow();
                              setCurrentUser(data);
                            }}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
                <th>-</th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
