import React from 'react';
import { useTranslation } from 'react-i18next';
interface iUserCreateProps {
  filterData: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  userRoleList: string[];
}

export const UserFiltering = ({
  filterData,
  userRoleList,
}: iUserCreateProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">{t('Users.list_of_users')}</h4>
        </div>
        <p className="tx-12 tx-gray-500 mb-2">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>
      </div>

      <form className="row card-header ">
        <div className="col-md-4 d-flex align-items-center">
          <input
            className="form-control w-100"
            name="name"
            onChange={filterData}
            placeholder={t('Users.search_by_name_and_lastname')}
            type="text"
            maxLength={30}
          />
          <i className="fas fa-search" style={{ marginLeft: '-2rem' }} />
        </div>
        <div className="col-md-4 mt-2 mt-md-0 me-0">
          <input
            type="email"
            className="form-control w-100"
            name="filterinput"
            onChange={filterData}
            placeholder={t('Users.search_by_email')}
          />
        </div>

        <div className="col-md-4 mt-2 mt-md-0">
          <select
            name="role"
            className="form-control form-select select2"
            onChange={filterData}
            data-bs-placeholder={t('Users.select_role')}
          >
            <option value="">{t('Users.all_roles')}</option>
            {userRoleList?.map((role: string, i: number) => {
              return (
                <option value={`${role}`} key={i}>
                  {role?.split('_').join(' ')}
                </option>
              );
            })}
          </select>
        </div>
      </form>
    </>
  );
};
