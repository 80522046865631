import {iCrud, iDataUpdateModalProps, iFileType} from "../../../model/app/Application";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import {BASE_PATH} from "../../../utils/helpers/api.routes";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import {Modals} from "../../../shared/modals/Modals";
import React from "react";
import {putCompanies} from "../Companies.service";
import {SuccessToast} from "../../../utils/toasters/toasters";

export const CompaniesUpdate = ({
                                    selectedData,
                                    modalRef,
                                    setTriggerRerender,
                                    triggerRerender,
                                    setSelectedData
                                }: iDataUpdateModalProps) => {

    console.log(selectedData)


    const modalDataHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if (event.target.name === 'file') {
            setSelectedData!((prev: iCrud | undefined) => ({
                ...prev,
                [event.target.name]: event.target.value
            }))
        } else {
            setSelectedData!((prev: iCrud | undefined) => ({
                ...prev,
                company: {
                    ...prev!.company,
                    [event.target.name]: event.target.value,
                }
            }))
        }
    };
    const updateCompany = () => {
        const modalData = new FormData();

        if (modalRef?.current?.files?.length) {
            for (let file in modalRef.current.files) {
                modalData.append('file', modalRef.current.files[file])
            }
        }
        if (selectedData!.company === undefined) {
            modalData.append('body', JSON.stringify({}))
        } else {
            modalData.append('body', JSON.stringify(selectedData!.company))
        }

        putCompanies(+localStorage.getItem('selectedCompanyID')!, modalData as any).then(res => {
            localStorage.removeItem('selectedCompanyID');
            if (res) {
                setTriggerRerender!(!triggerRerender)
                modalRef!.current = null
                SuccessToast('You have successfully updated company!')
            }

        }).catch(error => error)
    }

    return (
        <Modals modalId="modaldemo1"
                title={`Company - ID: ${selectedData?.id}`}
                body={
                    <div className="form-group mb-0">
                        <div>
                            <label htmlFor="files" className="font-weight-bold d-flex flex-column">Files:</label>
                            {selectedData!?.documents!?.length > 0 ? selectedData?.documents?.map((file: iFileType) => {
                                return (
                                    <div
                                        className="border py-2 px-1 my-2 d-flex align-items-center justify-content-between"
                                        key={file.id}>
                                        <div>
                                            <DriveFileMoveIcon className="text-muted"/>
                                            <span className="ms-1">
                                            {file?.file?.nameOriginal}
                                        </span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <a target="_blank"
                                               rel="noreferrer"
                                               href={`${BASE_PATH}/${file?.path}`}>
                                                <VisibilityIcon className="text-muted cursor-pointer"/>
                                            </a>
                                            <div
                                                onClick={() => localStorage.setItem('fileIDDelete', JSON.stringify(file.id))}
                                                data-bs-target="#deleteFile"
                                                data-bs-toggle="modal">
                                                <DeleteIcon className="text-secondary cursor-pointer"/>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }) : <p className="fw-bold">No files</p>}
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="title">Company name</label>
                                <input type="text" className="form-control updateModalField" name="title"
                                       defaultValue={selectedData?.title}
                                       key={selectedData?.title}
                                       onBlur={modalDataHandler}
                                       placeholder="Enter company name"/>
                            </div>
                            <div className="col-6">
                                <label htmlFor="email" className="my-2">Email</label>
                                <input type="email" className="form-control updateModalField" name="email"
                                       defaultValue={selectedData?.email}
                                       key={selectedData?.email}
                                       onBlur={modalDataHandler}
                                       placeholder="Enter email"/>
                            </div>
                            <div className="col-6">
                                <label htmlFor="address" className="my-2">Address</label>
                                <input type="text" className="form-control updateModalField" name="address"
                                       defaultValue={selectedData!?.address}
                                       key={selectedData!?.address}
                                       onBlur={modalDataHandler}
                                       placeholder="Enter address"/>
                            </div>
                            <div className="col-6">

                                <label htmlFor="zip" className="my-2">Zip</label>
                                <input type="text" className="form-control updateModalField" name="zip"
                                       defaultValue={selectedData?.zip}
                                       key={selectedData?.zip}
                                       onBlur={modalDataHandler}
                                       placeholder="Enter zip"/>
                            </div>

                            <div className="col-6">
                                <label htmlFor="country" className="my-2">Country</label>
                                <input type="text" className="form-control updateModalField" name="country"
                                       defaultValue={selectedData?.country}
                                       key={selectedData?.country}
                                       onBlur={modalDataHandler}
                                       placeholder="Enter country"/>
                            </div>

                            <div className="col-6">
                                <label htmlFor="phone" className="my-2">Phone</label>
                                <input type="text" className="form-control updateModalField" name="phone"
                                       defaultValue={selectedData?.phone}
                                       key={selectedData?.phone}
                                       onBlur={modalDataHandler}
                                       placeholder="Enter phone"/>
                            </div>
                            <div className="col-6">
                                <label htmlFor="title" className="my-2">Reg Number</label>
                                <input type="text" className="form-control updateModalField" name="regNumber"
                                       defaultValue={selectedData?.regNumber}
                                       key={selectedData?.regNumber}
                                       onBlur={modalDataHandler}
                                       placeholder="Enter reg number"/>
                            </div>
                            <div className="col-6">
                                <label htmlFor="file" className="my-2">Files</label>
                                <div className="form-control d-flex align-items-center justify-content-start px-0">
                                    <input type="file"
                                           onChange={modalDataHandler}
                                           name="file"
                                           ref={modalRef} multiple/>
                                </div>
                            </div>
                        </div>
                    </div>
                } updateChanges={updateCompany}/>
    )
}