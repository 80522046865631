import {iSelectedModalData, iTempSelectArray, iTenderCreate} from "../../../model/app/Application";
import {useEffect} from "react";


let tempCompanyArray: iTempSelectArray[] = [];
let tempClientArray: iTempSelectArray[] = [];
let tempTypeArray: iTempSelectArray[] = [];
let tempLocationArray: iTempSelectArray[] = [];
let tempCommissionerArray: iTempSelectArray[] = [];
let tempSupervisorArray: iTempSelectArray[] = [];


export const useTenderSelect = ({
                                    commissionerList,
                                    locationList,
                                    supervisorList,
                                    clientList,
                                    userRoleList,
                                    companyList,
                                    typeList,
                                    selectedData
                                }: iTenderCreate) => {

    const optionsClient = (
        clientList?.map((client: iSelectedModalData) => {
            return {
                value: client?.id, label: client?.name, isClient: true
            }
        })
    )

    const optionsRole = (
        userRoleList?.map((role: any) => {
            return {
                value: role, label: role?.split('_').join(' '), isRole: true
            }
        })
    )

    const optionsCompany = (
        companyList?.map((company: iSelectedModalData) => {
            return {
                value: company?.id, label: company?.title, isCompany: true
            }
        })
    )

    const optionsCommissioner = (
        commissionerList?.map((commissioner: iSelectedModalData) => {
            return {
                value: commissioner?.id, label: commissioner?.name, isCommissioner: true
            }
        })
    )

    const optionsLocation = (
        locationList?.map((location: iSelectedModalData) => {
            return {
                value: location?.id, label: location?.title, isLocation: true
            }
        })
    )

    const optionsTenderType = (
        typeList?.map((location: iSelectedModalData) => {
            return {
                value: location?.id, label: location?.title, isTenderType: true
            }
        })
    )

    const optionsSupervisor = (
        supervisorList?.map((supervisor: iSelectedModalData) => {
            return {
                value: supervisor?.id, label: `${supervisor?.firstName} ${supervisor?.lastName}`, isSupervisor: true
            }
        })
    )

    useEffect(() => {
        if (selectedData?.tender) {
            // localStorage.setItem('selectedCompanyID', selectedData?.tender?.supervisor?.company?.id)
            tempCompanyArray.push({
                label: selectedData?.tender?.supervisor?.company?.title,
                isCompany: true,
                value: selectedData?.tender?.supervisor?.company?.id
            })
            tempClientArray.push({
                label: selectedData?.tender?.client?.name,
                isClient: true,
                value: selectedData?.tender?.client?.id
            })
            tempTypeArray.push({
                label: selectedData?.tender?.tenderType?.title,
                isTenderType: true,
                value: selectedData?.tender?.tenderType?.id
            })
            tempLocationArray.push({
                label: selectedData?.tender?.location?.title,
                isLocation: true,
                value: selectedData?.tender?.location?.id
            })
            tempCommissionerArray.push({
                label: selectedData?.tender?.commissioner?.name,
                isCommissioner: true,
                value: selectedData?.tender?.commissioner?.id
            })

            tempSupervisorArray.push({
                label: `${selectedData?.tender?.supervisor?.firstName} ${selectedData?.tender?.supervisor?.lastName}`,
                isSupervisor: true,
                value: selectedData?.tender?.supervisor?.id
            })
        }
    }, [JSON.stringify(selectedData?.tender)])

    return {
        optionsSupervisor,
        optionsTenderType,
        optionsLocation,
        optionsCommissioner,
        optionsClient,
        optionsCompany,
        tempCompanyArray,
        tempCommissionerArray,
        optionsRole,
        tempClientArray,
        tempLocationArray,
        tempTypeArray,
        tempSupervisorArray
    }
}