import React from "react";
import {Pagination} from "../../../shared/paginations/Paginations";
import {useTender} from "../../../shared/hooks/app/useTender";
import {addLoss, deleteLoss, getLoss, getLosses, putLoss} from "../Loss.service";
import {LossTable} from "./LossTable";
import {LossCreate} from "./LossCreate";
import {LossUpdateModal} from "./LossUpdateModal";
import {LossDeleteModal} from "./LossDeleteModal";

export function LossContent() {

    const {
        page,
        handlePages,
        totalPages,
        listOfAllData,
        updateData,
        createData,
        removeData,
        selectedModalData,
        getCurrentDataID,
        filterData,
        changeHandler,
        showForm,
        setShowForm,
    } = useTender({
        getData: getLoss,
        putData: putLoss,
        deleteData: deleteLoss,
        getMany: getLosses,
        addData: addLoss
    });


    return (
        <>
            <div className="row row-sm">
                <LossCreate showForm={showForm} setShowForm={setShowForm} createData={createData}
                            changeHandler={changeHandler}/>
            </div>

            <div className="row">
                <LossTable filterData={filterData} getCurrentDataID={getCurrentDataID} listOfAllData={listOfAllData}/>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-end">
                {totalPages <= 1 ? '' :
                    <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                }
            </div>
            <LossUpdateModal selectedData={selectedModalData!} changeHandler={changeHandler} updateData={updateData}/>
            <LossDeleteModal removeData={removeData} selectedModalData={selectedModalData!}/>
        </>
    )
}
