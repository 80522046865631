import { iDataUpdateModalProps } from '../../../model/app/Application';
import { Modals } from '../../../shared/modals/Modals';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const LossUpdateModal = ({
  selectedData,
  changeHandler,
  updateData,
}: iDataUpdateModalProps) => {
  const { t } = useTranslation();
  return (
    <Modals
      modalId="modaldemo1"
      title={`${t('Loss.loss')} - ID: ${selectedData?.id}`}
      body={
        <div className="form-group mb-0">
          <label htmlFor="title">{t('Loss.reason')}</label>
          <input
            type="text"
            className="form-control updateModalField"
            name="reason"
            key={selectedData?.reason}
            defaultValue={selectedData?.reason}
            onBlur={changeHandler}
            placeholder={t('Loss.enter_reason')}
          />
        </div>
      }
      updateChanges={updateData!}
    />
  );
};
