import { MyProfileContent } from './MyProfileContent';
import { PageTemplate } from '../../../shared/layout/PageTemplate';

export function MyProfile() {
  return (
    <PageTemplate
      pageName="profile"
      title="Profile"
      content={<MyProfileContent />}
    />
  );
}
