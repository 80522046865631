import { BASE_PATH } from '../../../utils/helpers/api.routes';
import { useTranslation } from 'react-i18next';
export function MyProfileContent() {
  const { t } = useTranslation();

  const { user } = JSON.parse(localStorage.getItem('currentUser')!);
  console.log(user);
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body d-md-flex">
              <div className="">
                <span className="profile-image pos-relative">
                  <img
                    className="br-5"
                    alt="Profile logo"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = '/images/placeholderLogo.png';
                    }}
                    src={`${BASE_PATH}/${user?.profilePicture?.path}`}
                  />
                  <span className="bg-success text-white wd-1 ht-1 rounded-pill profile-online" />
                </span>
              </div>
              <div className="my-md-auto mt-4 prof-details">
                <h4 className="font-weight-semibold ms-md-4 ms-0 mb-1 pb-0">
                  {user.firstName} {user.lastName}
                </h4>
                <p className="tx-13 text-muted ms-md-4 ms-0 mb-2 pb-2 ">
                  {user?.roles?.map((role: string) => {
                    return (
                      <span className="me-3">
                        <i className="far fa-address-card me-2" />
                        {role === 'ROLE_SUPER_ADMIN' ? 'SUPER ADMIN' : role}
                      </span>
                    );
                  })}
                  <span>
                    <i className="far fa-flag me-2" />
                    {user?.company.country}
                  </span>
                </p>
                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <i className="fa fa-phone me-2" />
                  <span className="font-weight-semibold me-2">
                    {t('Profile.phone')}:
                  </span>
                  {user?.company?.phone}
                </p>
                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <i className="fa fa-envelope me-2" />
                  <span className="font-weight-semibold me-2">
                    {t('Profile.email')}::
                  </span>
                  {user?.email}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-sm">
        <div className="col-lg-12 col-md-12">
          <div className="custom-card main-content-body-profile">
            <div className="tab-content">
              <div className="main-content-body tab-pane  border-0" id="theme">
                <div className="card">
                  <div
                    className="card-body border-0"
                    data-select2-id="12"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
