import React, { useRef, useState } from 'react';
import { iTenderCreate } from '../../../model/app/Application';
import { multipartFormData } from '../../../utils/helpers/api.services';
import { BASE_PATH } from '../../../utils/helpers/api.routes';
import { ErrorToast, SuccessToast } from '../../../utils/toasters/toasters';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
export const CompaniesCreate = ({
  setShowForm,
  triggerRerender,
  setTriggerRerender,
}: iTenderCreate) => {
  const inputRef = useRef<any>(null);
  const { t } = useTranslation();

  const [company, setCompany] = useState({
    company: {
      title: '',
      email: '',
      address: '',
      zip: '',
      country: '',
      phone: '',
      regNumber: '',
    },
    user: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    file: [],
  });
  const [showPassword, setShowPassword] = useState(true);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    const list = document.getElementById('result');
    let child;

    switch (event.target.name) {
      case 'firstName':
      case 'lastName':
      case 'password':
        setCompany((prev) => ({
          ...prev,
          user: {
            ...prev.user,
            [event.target.name]: event.target.value,
          },
        }));
        break;
      case 'file':
        for (let i = 0; i < files!.length; i++) {
          child = document.createElement('li');
          child.textContent = files![i].name;

          list!.append(child);
        }
        setCompany((prev) => ({
          ...prev,
          [event.target.name]: (event.target as HTMLInputElement).files,
        }));
        break;
      case 'email-user':
        setCompany((prev) => ({
          ...prev,
          user: {
            ...prev.user,
            email: event.target.value,
          },
        }));
        break;
      default:
        setCompany((prev) => ({
          ...prev,
          company: {
            ...prev!.company,
            [event.target.name]: event.target.value,
          },
        }));
    }
  };

  const createData = () => {
    multipartFormData(inputRef, `${BASE_PATH}/admin/companies`, company, 'POST')
      .then((res) => {
        if (res) {
          setShowForm!(false);
          setTriggerRerender!(!triggerRerender);
          SuccessToast('Company successfully created');
        }
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <h6 className="text-uppercase font-weight-bold">
            {t('Companies.company_details')}
          </h6>
          <div className="row">
            <div className="col-md-6 mt-3">
              <label htmlFor="exampleInputEmail1">
                <span className="text-danger">*</span>
                {t('Companies.company_name')}
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                onChange={changeHandler}
                placeholder={t('Companies.enter_name')}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="email">
                <span className="text-danger">*</span>
                {t('Companies.email')}
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                onChange={changeHandler}
                placeholder={t('Companies.enter_email')}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="address">{t('Companies.address')}</label>
              <input
                type="text"
                className="form-control"
                name="address"
                onChange={changeHandler}
                placeholder={t('Companies.enter_address')}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="address">{t('Companies.reg_number')}</label>
              <input
                type="text"
                className="form-control"
                name="regNumber"
                onChange={changeHandler}
                placeholder={t('Companies.enter_reg_number')}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="zip">{t('Companies.zip')}</label>
              <input
                type="text"
                className="form-control"
                name="zip"
                onChange={changeHandler}
                placeholder={t('Companies.enter_zip')}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="country">{t('Companies.country')}</label>
              <input
                type="text"
                className="form-control"
                name="country"
                onChange={changeHandler}
                placeholder={t('Companies.enter_country')}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="phone">{t('Companies.phone')}</label>
              <input
                type="text"
                className="form-control"
                name="phone"
                onChange={changeHandler}
                placeholder={t('Companies.enter_phone_number')}
              />
            </div>
            <div className="col-12 mt-4">
              <h6 className="text-uppercase font-weight-bold">
                {t('Companies.user_details')}
              </h6>
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="firstName">
                <span className="text-danger">*</span>
                {t('Companies.first_name')}
              </label>
              <input
                type="text"
                className="form-control"
                name="firstName"
                onChange={changeHandler}
                placeholder={t('Companies.enter_firstname')}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="lastName">{t('Companies.last_name')}</label>
              <input
                type="text"
                className="form-control"
                name="lastName"
                onChange={changeHandler}
                placeholder={t('Companies.enter_lastname')}
              />
            </div>

            <div className="col-md-6 mt-3">
              <label htmlFor="email">
                <span className="text-danger">*</span>
                {t('Companies.email')}
              </label>
              <input
                type="text"
                className="form-control"
                name="email-user"
                autoComplete="false"
                onChange={changeHandler}
                placeholder={t('Companies.enter_email')}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="password">
                <span className="text-danger">*</span>
                {t('Companies.password')}
              </label>
              <div className="d-flex align-items-center">
                <input
                  type={
                    showPassword ? t('Companies.password') : t('Companies.text')
                  }
                  className="form-control"
                  name="password"
                  autoComplete="new-password"
                  onChange={changeHandler}
                  placeholder={t('Companies.enter_password')}
                />
                {showPassword ? (
                  <VisibilityOffIcon
                    onClick={() => setShowPassword(false)}
                    className="text-gray ms--2 cursor-pointer"
                  />
                ) : (
                  <VisibilityIcon
                    onClick={() => setShowPassword(true)}
                    className="text-gray ms--2 cursor-pointer"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <h6 className="text-uppercase font-weight-bold ">
            {t('Companies.company_documents')}
          </h6>
          <label htmlFor="file" className="mt-3">
            {t('Companies.upload_files')}
          </label>
          <div className="form-control d-flex align-items-center justify-content-start px-0">
            <input
              type="file"
              onChange={changeHandler}
              name="file"
              ref={inputRef}
              multiple
            />
          </div>
          <h6 className="text-uppercase font-weight-bold mt-3">
            {t('Companies.companies_documents')}
          </h6>
          <ul id="result"></ul>
        </div>
      </div>
      <div className="d-flex justify-content-end w-100">
        <button onClick={createData} className="btn btn-primary mt-3 mb-0">
          {t('Companies.create_company')}
        </button>
      </div>
    </>
  );
};
