import React from "react";
import {Pagination} from "../../../shared/paginations/Paginations";
import {useTender} from "../../../shared/hooks/app/useTender";
import {
    addIrrelevance,
    deleteIrrelevance,
    getIrrelevance,
    getIrrelevances,
    putIrrelevance
} from "../Irrelevance.service";
import {IrrelevanceCreate} from "./IrrelevanceCreate";
import {IrrelevanceTable} from "./IrrelevanceTable";
import {IrrelevanceUpdateModal} from "./IrrelevanceUpdateModal";
import {IrrelevanceDeleteModal} from "./IrrelevanceDeleteModal";

export function IrrelevanceContent() {

    const {
        page,
        handlePages,
        totalPages,
        listOfAllData,
        updateData,
        removeData,
        createData,
        getCurrentDataID,
        changeHandler,
        filterData,
        showForm,
        setShowForm,
        selectedData
    } = useTender({
        getData: getIrrelevance,
        putData: putIrrelevance,
        getMany: getIrrelevances,
        deleteData: deleteIrrelevance,
        addData: addIrrelevance
    });

    return (
        <>
            <div className="row row-sm">
                <div className="col-12">
                    <IrrelevanceCreate showForm={showForm} setShowForm={setShowForm} createData={createData}
                                       changeHandler={changeHandler}/>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <IrrelevanceTable filterData={filterData} getCurrentDataID={getCurrentDataID}
                                      listOfAllData={listOfAllData}/>
                </div>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-end">
                {totalPages <= 1 ? '' :
                    <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                }
            </div>
            <IrrelevanceUpdateModal selectedData={selectedData!} changeHandler={changeHandler} updateData={updateData}/>
            <IrrelevanceDeleteModal removeData={removeData} selectedData={selectedData}/>
        </>
    )
}