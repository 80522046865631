import React, { useEffect, useState } from 'react';
import { Pagination } from '../../../shared/paginations/Paginations';
import { useTender } from '../../../shared/hooks/app/useTender';
import { deleteUser } from '../Users.service';
import {
  addUser,
  getUser,
  getUsers,
  putPasswordUser,
  putUser,
} from '../Users.service';
import { getCompanies } from '../../companies/Companies.service';
import { getUserRoles } from '../../../model/app/UserRoles.service';
import { useTenderSelect } from '../../../shared/hooks/app/useTenderSelect';
import { UserFiltering } from './UserFiltering';
import { UserTable } from './UserTable';
import { UserCreate } from './UserCreate';
import { UserUpdateModal } from './UserUpdateModal';
import { UserChangePasswordModal } from './UserChangePasswordModal';
import {
  iCrud,
  iPagination,
  iResponseCrud,
} from '../../../model/app/Application';
import { searchDto } from '../../../shared/functions/Functions';
import { putUserStatus } from '../Users.service';
import _debounce from 'lodash/debounce';
import { ErrorToast, SuccessToast } from '../../../utils/toasters/toasters';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
export function UsersContent() {
  const { t } = useTranslation();
  const { createData, changeHandler, modalRef, showForm, setShowForm } =
    useTender({
      getData: getUser,
      putData: putUser,
      getMany: getUsers,
      addData: addUser,
    });
  const [page, setPage] = useState(1);
  const [companyList, setCompanyList] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [showPassword, setShowPassword] = useState(true);
  const [selectedData, setSelectedData] = useState<iCrud>();
  const [listOfAllData, setListOfAllData] = useState<iResponseCrud[]>();
  const [totalPages, setTotalPages] = useState(0);
  const [triggerRerender, setTriggerRerender] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>();
  const { optionsCompany, optionsRole } = useTenderSelect({
    companyList,
    userRoleList,
  });
  const [pagination, setPagination] = useState<iPagination>({
    page: 1,
    perPage: 10,
    name: '',
    role: '',
    email: '',
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getCompanies({ perPage: 999999 })
      .then((res) => {
        res && setCompanyList(res.data);
      })
      .catch((error) => error);

    getUserRoles()
      .then((res) => {
        res && setUserRoleList(res);
      })
      .catch((error) => error);
  }, []);

  const getCurrentDataID = (id: number) => {
    localStorage.setItem('selectedCompanyID', JSON.stringify(id));
    getUser(id)
      .then((res) => {
        setSelectedData(res);
      })
      .catch((error) => error);
  };

  const changePasswordHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const changeUserPassword = () => {
    putPasswordUser(+selectedData?.id!, selectedData)
      .then((res) => {
        res && SuccessToast('You have successfully updated password');
      })
      .catch((error) => error);
  };

  const toggleUserStatus = async (id: number) => {
    putUserStatus(id)
      .then((res: any) => {
        setTriggerRerender(!triggerRerender);
      })
      .catch((error: any) => {
        ErrorToast(error);
      });
  };

  const removeUser = () => {
    deleteUser(currentUser?.id)
      .then((res: any) => {
        setTriggerRerender(!triggerRerender);
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };

  useEffect(() => {
    const paginationState = searchDto(pagination);
    getUsers(paginationState)
      .then((data) => {
        if (data) {
          setTotalPages(Math.ceil(data?.count / data?.perPage));
          setListOfAllData(data?.data);
        }
      })
      .catch((error) => error);
  }, [
    JSON.stringify(triggerRerender),
    JSON.stringify(page),
    JSON.stringify(pagination),
  ]);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const filterData = _debounce(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (event.target.name === 'filterinput') {
        setPagination((prev) => ({ ...prev, email: event.target.value }));
      } else {
        setPagination((prev) => ({
          ...prev,
          [event.target.name]: event.target.value,
        }));
      }
    },
    100
  );

  return (
    <>
      <div className="row row-sm">
        <div className="col-12">
          <UserCreate
            showForm={showForm}
            setShowForm={setShowForm}
            changeHandler={changeHandler}
            triggerRerender={triggerRerender}
            setTriggerRerender={setTriggerRerender}
            optionsCompany={optionsCompany}
            optionsRole={optionsRole}
            createData={createData}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <UserFiltering
              filterData={filterData}
              userRoleList={userRoleList}
            />
            <UserTable
              listOfAllData={listOfAllData}
              toggleUserStatus={toggleUserStatus}
              getCurrentDataID={getCurrentDataID}
              handleShow={handleShow}
              setCurrentUser={setCurrentUser}
            />
          </div>
        </div>
      </div>
      <div className="w-100 d-flex align-items-center justify-content-end">
        {totalPages <= 1 ? (
          ''
        ) : (
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePagination={handlePages}
          />
        )}
      </div>
      <UserUpdateModal
        companyList={companyList}
        triggerRerender={triggerRerender}
        setTriggerRerender={setTriggerRerender}
        userRoleList={userRoleList}
        modalRef={modalRef}
        setSelectedData={setSelectedData}
        selectedData={selectedData}
      />

      <UserChangePasswordModal
        selectedData={selectedData}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        changeHandler={changePasswordHandler}
        changeUserPassword={changeUserPassword}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{`Delete user id ${currentUser?.id}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are going to delete this user permanently!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={removeUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
