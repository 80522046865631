import {requests} from "../../utils/helpers/api.services";
import {BASE_PATH} from "../../utils/helpers/api.routes";
import {ErrorToast} from "../../utils/toasters/toasters";
import {iCrud, iGetData} from "../../model/app/Application";

export function addLocations(body: iCrud) {
    return requests.post(`${BASE_PATH}/admin/locations`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getLocations(body?: iGetData) {
    return requests.get(`${BASE_PATH}/admin/locations`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function putLocations(id: number, body?: iCrud) {
    return requests.put(`${BASE_PATH}/admin/locations/${id}`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getLocation(id: number) {
    return requests.get(`${BASE_PATH}/admin/locations/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error));
}