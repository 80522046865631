import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import React, {useState, useEffect} from "react";
import {ForgotPasswordChange, ForgotPasswordRequest, iForgotPasswordRequest} from "./ResetPassword.service";
import {SuccessToast, WarningToast} from "../../utils/toasters/toasters";

export const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [visibilityPassword, setVisibility] = useState(false)
    const [forgotState, setForgotState] = useState<iForgotPasswordRequest>({
        email: ''
    })
    const [queryParams, setQueryParams] = useState({
        code: '',
        email: '',
    })
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForgotState(prev => ({...prev, [event.target.name]: event.target.value}))
    }

    const sendData = () => {
        if (queryParams?.code) {
            if (forgotState?.password_repeat !== forgotState?.password) return WarningToast('Enter two same passwords!')
            ForgotPasswordChange({
                ...forgotState,
                email: queryParams?.email,
                code: queryParams?.code
            }).then(res => {
                SuccessToast('You have successfully changed your password, please log in!')
                navigate('/')
            }).catch(error => error);
        } else {
            ForgotPasswordRequest(forgotState).then(res => {
                SuccessToast('Check email!')
                navigate('/')
            }).catch(error => error);
        }
    }


    useEffect(() => {
        setQueryParams({
            code: searchParams.get("code") as string,
            email: searchParams.get("email") as string
        })
    }, [])

    return (
        <div className="bg-primary">
            <div className="page">
                <div className="page-single ">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main mx-auto my-auto py-45 justify-content-center">
                                <div className="card-sigin mt-5 mt-md-0">
                                    <div className="main-card-signin d-md-flex">
                                        <div className="wd-100p">
                                            <div className="d-flex mb-4 justify-content-center">
                                                <img src="/images/bbi-logo-blue.png" className="sign-favicon ht-40"
                                                     alt="logo"/>
                                            </div>
                                            <div className="">
                                                <div className="main-signup-header">
                                                    <h2 className="text-center"> {queryParams?.code ? 'Please enter new password' : 'Forgot Password!'} </h2>
                                                    <h6 className="font-weight-semibold mb-4 text-center">

                                                        {queryParams?.code ? 'Password reset' : 'Please Enter Your Email'}
                                                    </h6>
                                                    <div className="panel panel-primary">

                                                        <div className="panel-body tabs-menu-body border-0 p-3">
                                                            <div className="tab-content">
                                                                <div className="tab-pane active" id="tab5">

                                                                    {queryParams?.code && <div className="form-group">
                                                                        <label className="form-label"
                                                                               htmlFor="password-input">Password</label>
                                                                        <div
                                                                            className="position-relative auth-pass-inputgroup mb-3">
                                                                            <input name="password"
                                                                                   onChange={changeHandler}
                                                                                   type={`${visibilityPassword ? 'text' : 'password'}`}
                                                                                   className="form-control pe-5"
                                                                                   placeholder="Enter password"
                                                                                   id="password-input"
                                                                                // onKeyUp={loginPressingEnter}
                                                                            />
                                                                            <button
                                                                                className="btn position-absolute pw-btn text-decoration-none text-muted"
                                                                                type="button" id="password-addon"
                                                                                onClick={() => setVisibility(!visibilityPassword)}
                                                                            >
                                                                                {!visibilityPassword ?
                                                                                    <VisibilityOffIcon
                                                                                        onClick={() => setVisibility(false)}
                                                                                        className="text-gray ms--2 cursor-pointer"/> :
                                                                                    <VisibilityIcon
                                                                                        onClick={() => setVisibility(true)}
                                                                                        className="text-gray ms--2 cursor-pointer"/>
                                                                                }
                                                                            </button>
                                                                        </div>
                                                                    </div>}
                                                                    <div className="form-group">
                                                                        <label className="form-label"
                                                                               htmlFor={queryParams?.code ? 'password' : 'email'}>
                                                                            {queryParams?.code ? 'Repeat password' : 'Email'}
                                                                        </label>
                                                                        <input
                                                                            name={queryParams?.code ? 'password_repeat' : 'email'}
                                                                            onChange={changeHandler}
                                                                            className="form-control"
                                                                            id="emailInputField"
                                                                            placeholder={queryParams?.code ? 'Repeat password' : 'Enter your email'}
                                                                            type={queryParams?.code ? 'password' : 'text'}
                                                                        />
                                                                    </div>
                                                                    <button
                                                                        onClick={sendData}
                                                                        className="btn btn-primary btn-block"
                                                                    >
                                                                        {queryParams?.code ? 'Change password' : 'Send Reset Link'}

                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="main-signin-footer text-center mt-3">
                                                        <p>
                                                            <Link to="/"
                                                                  className="mb-3">
                                                                Wait, I remember my password...
                                                            </Link>

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}