import { useDashboard } from '../../shared/hooks/app/useDashboard';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Apartment, DocumentScanner, Groups } from '@mui/icons-material';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { useTranslation } from 'react-i18next';

export function DashboardContent() {
  const {
    numberOfUsers,
    numberOfCommissioners,
    numberOfClients,
    numberOfCompanies,
    numberOfTenders,
    numberOfLocations,
    numberOfTypes,
    numberOfIrrelevances,
    numberOfCancellations,
    numberOfLosses,
  } = useDashboard();
  const { t } = useTranslation();
  return (
    <>
      {/*  row  */}
      <div className="row row-sm">
        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="card sales-card circle-image1">
            <div className="row">
              <div className="col-8">
                <div className="ps-4 pt-4 pe-3 pb-4 pt-0">
                  <div className="">
                    <h6 className="mb-2 tx-12 text-uppercase">
                      {t('Dashboard.total_users')}
                    </h6>
                  </div>
                  <div className="pb-0 mt-0">
                    <div className="d-flex">
                      <h4 className="tx-26 font-weight-semibold mb-2">
                        {numberOfUsers > 0 ? numberOfUsers : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="circle-icon widget bg-primary-gradient text-center d-flex align-items-center
                                    justify-content-center
                                    shadow-primary overflow-hidden box-shadow-primary"
                >
                  <Groups className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="card sales-card circle-image1">
            <div className="row">
              <div className="col-8">
                <div className="ps-4 pt-4 pe-3 pb-4 pt-0">
                  <div className="">
                    <h6 className="mb-2 tx-12 text-uppercase">
                      {t('Dashboard.total_commissioners')}
                    </h6>
                  </div>
                  <div className="pb-0 mt-0">
                    <div className="d-flex">
                      <h4 className="tx-26 font-weight-semibold mb-2">
                        {numberOfCommissioners > 0 ? numberOfCommissioners : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="circle-icon widget bg-primary-gradient text-center align-items-center justify-content-center d-flex
                                    shadow-primary overflow-hidden box-shadow-primary"
                >
                  <PersonOutlineOutlinedIcon className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="card sales-card circle-image1">
            <div className="row">
              <div className="col-8">
                <div className="ps-4 pt-4 pe-3 pb-4 pt-0">
                  <div className="">
                    <h6 className="mb-2 tx-12 text-uppercase">
                      {t('Dashboard.total_clients')}
                    </h6>
                  </div>
                  <div className="pb-0 mt-0">
                    <div className="d-flex">
                      <h4 className="tx-26 font-weight-semibold mb-2">
                        {numberOfClients > 0 ? numberOfClients : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="circle-icon widget bg-primary-gradient text-center d-flex
                                    align-items-center justify-content-center
                                    shadow-primary overflow-hidden box-shadow-primary"
                >
                  <PersonSearchOutlinedIcon className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="card sales-card circle-image1">
            <div className="row">
              <div className="col-8">
                <div className="ps-4 pt-4 pe-3 pb-4 pt-0">
                  <div className="">
                    <h6 className="mb-2 tx-12 text-uppercase">
                      {t('Dashboard.total_companies')}
                    </h6>
                  </div>
                  <div className="pb-0 mt-0">
                    <div className="d-flex">
                      <h4 className="tx-26 font-weight-semibold mb-2">
                        {numberOfCompanies > 0 ? numberOfCompanies : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="circle-icon widget bg-primary-gradient text-center d-flex align-items-center justify-content-center
                                    shadow-primary overflow-hidden box-shadow-primary"
                >
                  <Apartment className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="card sales-card circle-image1">
            <div className="row">
              <div className="col-8">
                <div className="ps-4 pt-4 pe-3 pb-4 pt-0">
                  <div className="">
                    <h6 className="mb-2 tx-12 text-uppercase">
                      {t('Dashboard.total_tenders')}
                    </h6>
                  </div>
                  <div className="pb-0 mt-0">
                    <div className="d-flex">
                      <h4 className="tx-26 font-weight-semibold mb-2">
                        {numberOfTenders > 0 ? numberOfTenders : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="circle-icon widget bg-primary-gradient text-center d-flex align-items-center justify-content-center
                                    shadow-primary overflow-hidden box-shadow-primary"
                >
                  <DocumentScanner className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="card sales-card circle-image1">
            <div className="row">
              <div className="col-8">
                <div className="ps-4 pt-4 pe-3 pb-4 pt-0">
                  <div className="">
                    <h6 className="mb-2 tx-12 text-uppercase">
                      {t('Dashboard.total_locations')}
                    </h6>
                  </div>
                  <div className="pb-0 mt-0">
                    <div className="d-flex">
                      <h4 className="tx-26 font-weight-semibold mb-2">
                        {numberOfLocations > 0 ? numberOfLocations : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="circle-icon widget bg-primary-gradient text-center d-flex align-items-center justify-content-center
                                    shadow-primary overflow-hidden box-shadow-primary"
                >
                  <MapOutlinedIcon className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="card sales-card circle-image1">
            <div className="row">
              <div className="col-8">
                <div className="ps-4 pt-4 pe-3 pb-4 pt-0">
                  <div className="">
                    <h6 className="mb-2 tx-12 text-uppercase">
                      {t('Dashboard.total_types')}
                    </h6>
                  </div>
                  <div className="pb-0 mt-0">
                    <div className="d-flex">
                      <h4 className="tx-26 font-weight-semibold mb-2">
                        {numberOfTypes > 0 ? numberOfTypes : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="circle-icon widget bg-primary-gradient text-center d-flex align-items-center justify-content-center
                                    shadow-primary overflow-hidden box-shadow-primary"
                >
                  <ExtensionOutlinedIcon className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="card sales-card circle-image1">
            <div className="row">
              <div className="col-8">
                <div className="ps-4 pt-4 pe-3 pb-4 pt-0">
                  <div className="">
                    <h6 className="mb-2 tx-12 text-uppercase">
                      {t('Dashboard.total_irrelevances')}
                    </h6>
                  </div>
                  <div className="pb-0 mt-0">
                    <div className="d-flex">
                      <h4 className="tx-26 font-weight-semibold mb-2">
                        {numberOfIrrelevances > 0 ? numberOfIrrelevances : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="circle-icon widget bg-primary-gradient text-center d-flex align-items-center justify-content-center
                                    shadow-primary overflow-hidden box-shadow-primary"
                >
                  <InsertChartOutlinedIcon className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="card sales-card circle-image1">
            <div className="row">
              <div className="col-8">
                <div className="ps-4 pt-4 pe-3 pb-4 pt-0">
                  <div className="">
                    <h6 className="mb-2 tx-12 text-uppercase">
                      {t('Dashboard.total_cancellations')}
                    </h6>
                  </div>
                  <div className="pb-0 mt-0">
                    <div className="d-flex">
                      <h4 className="tx-26 font-weight-semibold mb-2">
                        {numberOfCancellations > 0 ? numberOfCancellations : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="circle-icon widget bg-primary-gradient text-center d-flex align-items-center justify-content-center
                                    shadow-primary overflow-hidden box-shadow-primary"
                >
                  <CancelPresentationOutlinedIcon className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="card sales-card circle-image1">
            <div className="row">
              <div className="col-8">
                <div className="ps-4 pt-4 pe-3 pb-4 pt-0">
                  <div className="">
                    <h6 className="mb-2 tx-12 text-uppercase">
                      {t('Dashboard.total_losses')}
                    </h6>{' '}
                  </div>
                  <div className="pb-0 mt-0">
                    <div className="d-flex">
                      <h4 className="tx-26 font-weight-semibold mb-2">
                        {numberOfLosses > 0 ? numberOfLosses : 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="circle-icon widget bg-primary-gradient text-center d-flex align-items-center justify-content-center
                                    shadow-primary overflow-hidden box-shadow-primary"
                >
                  <IndeterminateCheckBoxOutlinedIcon className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  /row  */}
    </>
  );
}
