import React from 'react';
import { formatDate } from '../../../shared/functions/Functions';
import { Modals } from '../../../shared/modals/Modals';
import { iDataDeleteModal } from '../../../model/app/Application';
import { useTranslation } from 'react-i18next';

export const LossDeleteModal = ({
  removeData,
  selectedModalData,
}: iDataDeleteModal) => {
  const { t } = useTranslation();
  return (
    <Modals
      modalId="deleteModal"
      buttonType="btn-secondary"
      title="Are you sure you want to delete this item?"
      saveButtonName="Delete"
      updateChanges={removeData}
      body={
        <div>
          <div>
            <label htmlFor="id" className="font-weight-bold">
              ID:
            </label>
            <span className="ms-1">{selectedModalData?.id}</span>
          </div>
          <div>
            <label htmlFor="reason" className="font-weight-bold">
              {t('Loss.reason')}:
            </label>
            <span className="ms-1 ">{selectedModalData?.reason}</span>
          </div>
          <div>
            <label htmlFor="createdAt" className="font-weight-bold">
              {t('Loss.created_at')}:
            </label>
            <span className="ms-1 ">
              {formatDate(selectedModalData!?.createdAt as string)}
            </span>
          </div>
          <div>
            <label htmlFor="updatedAt" className="font-weight-bold">
              {t('Loss.updated_at')}:
            </label>
            <span className="ms-1 ">
              {formatDate(selectedModalData!?.updatedAt as string)}
            </span>
          </div>
        </div>
      }
    />
  );
};
