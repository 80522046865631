import React from 'react';
import { iResponseCrud, iTable } from '../../../model/app/Application';
import { useTranslation } from 'react-i18next';
export const CompaniesTable = ({
  getCurrentDataID,
  listOfAllData,
  filterData,
}: iTable) => {
  const { t } = useTranslation();
  return (
    <div className="col-xl-12">
      <div className="card">
        <div className="card-header pb-0">
          <div className="d-flex justify-content-between">
            <h4 className="card-title mg-b-0">
              {t('Companies.list_of_companies')}
            </h4>
          </div>
          <p className="tx-12 tx-gray-500 mb-2">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </p>
        </div>
        <div className="row card-header ">
          <div className="col-md-4 d-flex align-items-center">
            <input
              className="form-control w-100"
              name="title"
              onChange={filterData}
              placeholder={t('Companies.search_by_name_and_lastname')}
              type="text"
              maxLength={30}
            />
            <i className="fas fa-search" style={{ marginLeft: '-2rem' }} />
          </div>
          <div className="col-md-4 mt-2 mt-md-0">
            <input
              type="text"
              className="form-control w-100"
              name="email"
              placeholder={t('Companies.search_by_email')}
              onChange={filterData}
            />
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered table-hover mb-0 text-md-nowrap">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t('Companies.company_name')}</th>
                  <th>{t('Companies.address')}</th>
                  <th>{t('Companies.country')}</th>
                  <th>{t('Companies.email')}</th>
                  <th>{t('Companies.phone')}</th>
                  <th>{t('Companies.zip')}</th>
                  <th>{t('Companies.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {listOfAllData!?.length > 0 ? (
                  listOfAllData?.map((data: iResponseCrud) => {
                    return (
                      <tr key={data.id}>
                        <th scope="row">{data.id}</th>
                        <td>{data.title ? data.title : '-'}</td>
                        <td>{data.address ? data.address : '-'}</td>
                        <td>{data.country ? data.country : '-'}</td>
                        <td>{data.email ? data.email : '-'}</td>
                        <td>{data.phone ? data.phone : '-'}</td>
                        <td>{data.zip ? data.zip : '-'}</td>
                        <td className="d-flex justify-content-center">
                          <button
                            className="btn btn-primary ripple"
                            onClick={() => getCurrentDataID!(data.id)}
                            data-bs-target="#modaldemo1"
                            data-bs-toggle="modal"
                          >
                            {t('Companies.change')}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
