import React from "react";
import {Pagination} from "../../../shared/paginations/Paginations";
import {useTender} from "../../../shared/hooks/app/useTender";
import {addType, deleteType, getType, getTypes, putType} from "../Type.service";
import {TypeTable} from "./TypeTable";
import {TypeCreate} from "./TypeCreate";
import {TypeUpdateModal} from "./TypeUpdateModal";
import {TypeDeleteModal} from "./TypeDeleteModal";

export function TypeContent() {

    const {
        page,
        handlePages,
        totalPages,
        listOfAllData,
        removeData,
        updateData,
        selectedModalData,
        createData,
        getCurrentDataID,
        filterData,
        changeHandler,
        showForm,
        setShowForm,
        selectedData
    } = useTender({
        getData: getType,
        putData: putType,
        deleteData: deleteType,
        getMany: getTypes,
        addData: addType
    });

    return (
        <>
            <div className="row row-sm">
                <TypeCreate createData={createData} changeHandler={changeHandler} setShowForm={setShowForm}
                            showForm={showForm}/>
            </div>

            <div className="row">
                <TypeTable getCurrentDataID={getCurrentDataID} listOfAllData={listOfAllData} filterData={filterData}/>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-end">
                {totalPages <= 1 ? '' :
                    <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                }
            </div>
            <TypeUpdateModal selectedData={selectedData!} changeHandler={changeHandler} updateData={updateData}/>
            <TypeDeleteModal removeData={removeData} selectedModalData={selectedModalData!}/>
        </>
    )
}
