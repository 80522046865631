import React, { useEffect, useState } from 'react';
import { Pagination } from '../../../shared/paginations/Paginations';
import { useTender } from '../../../shared/hooks/app/useTender';
import {
  addTender,
  getTender,
  getTenders,
  getDeletedTenders,
  getTenderStatuses,
  putTenders,
  deleteRestoreTender,
} from '../Tender.service';
import { getCommissioners } from '../../commissioner/Commissioner.service';
import { getLocations } from '../../location/Location.service';
import { getTypes } from '../../type/Type.service';
import {
  getCompanies,
  getPossibleSupervisors,
} from '../../companies/Companies.service';

import { TenderFiltering } from './TenderFiltering';
import { TenderCreate } from './TenderCreate';
import { TenderUpdate } from './TenderUpdate';
import {
  iCrud,
  iPagination,
  iResponseCrud,
} from '../../../model/app/Application';
import { searchDto } from '../../../shared/functions/Functions';
import _debounce from 'lodash/debounce';
import { ErrorToast } from '../../../utils/toasters/toasters';
import { useTranslation } from 'react-i18next';

export function TendersContent() {
  const {
    createData,
    changeHandler,
    showForm,
    setShowForm,
    changeSelectHandler,
  } = useTender({
    getData: getTender,
    putData: putTenders,
    getMany: getTenders,
    addData: addTender,
  });
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<iPagination>({
    page: 1,
    perPage: 10,
    name: '',
    role: '',
    email: '',
  });
  const [totalPages, setTotalPages] = useState(0);
  const [commissionerList, setCommissionerList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [supervisorList, setSupervisorList] = useState([]);
  const storedCompanyID = localStorage.getItem('selectedCompanyID');
  const [showEditTender, setShowEditTender] = useState(false);
  const [tenderStatus, setTenderStatus] = useState([]);
  const [selectedData, setSelectedData] = useState<iCrud>();
  const [listOfAllData, setListOfAllData] = useState<iResponseCrud[]>();
  const [triggerRerender, setTriggerRerender] = useState(false);
  const [isDeletedList, setIsDeletedList] = useState(false);
  const { t } = useTranslation('app');

  useEffect(() => {
    getCommissioners({ perPage: 999999 })
      .then((res) => {
        res && setCommissionerList(res.data);
      })
      .catch((error) => error);

    getLocations({ perPage: 999999 })
      .then((res) => {
        res && setLocationList(res.data);
      })
      .catch((error) => error);

    getTypes({ perPage: 999999 })
      .then((res) => {
        res && setTypeList(res.data);
      })
      .catch((error) => error);

    getCompanies({ perPage: 999999 })
      .then((res) => {
        res && setCompanyList(res.data);
      })
      .catch((error) => error);

    getTenderStatuses()
      .then((res) => {
        res && setTenderStatus(res);
      })
      .catch((error) => error);
  }, []);

  useEffect(() => {
    storedCompanyID &&
      getPossibleSupervisors(+storedCompanyID!)
        .then((res) => {
          setSupervisorList(res);
        })
        .catch((error) => error);
  }, [JSON.stringify(storedCompanyID), JSON.stringify(selectedData?.tender)]);

  useEffect(() => {
    const paginationState = searchDto(pagination);
    if (!isDeletedList) {
      getTenders(paginationState)
        .then((data) => {
          if (data) {
            setTotalPages(Math.ceil(data?.count / data?.perPage));
            setListOfAllData(data?.data);
          }
        })
        .catch((error) => error);
    } else {
      getDeletedTenders(paginationState)
        .then((data: any) => {
          if (data) {
            setTotalPages(Math.ceil(data?.count / data?.perPage));
            setListOfAllData(data?.data);
          }
        })
        .catch((error: any) => error);
    }
  }, [
    JSON.stringify(isDeletedList),
    JSON.stringify(triggerRerender),
    JSON.stringify(page),
    JSON.stringify(pagination),
  ]);

  const scrollIntoView = () => {
    window.scrollTo({ top: 150, behavior: 'smooth' });
  };

  const getCurrentDataID = (id: number) => {
    localStorage.setItem('selectedCompanyID', JSON.stringify(id));
    getTender(id)
      .then((res) => {
        setSelectedData(res);
      })
      .catch((error) => error);
  };

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const filterData = _debounce(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setPagination((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    },
    100
  );

  const filterTenderStatus = (
    status?: string,
    type?: string,
    event?: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (type === 'status') {
      setPagination((prev) => ({ ...prev, [type]: status }));
    } else {
      if (+event!) {
        setPagination((prev: any) => ({ ...prev, [type as string]: event })); // like this because of react-select library
      } else {
        setPagination((prev: any) => ({
          ...prev,
          [type as string]: event?.target?.value,
        }));
      }
    }
  };

  const changeList = () => {
    setIsDeletedList(!isDeletedList);
  };
  const deleteTender = (id: number) => {
    deleteRestoreTender(id)
      .then((res) => {
        setTriggerRerender(!triggerRerender);
      })
      .catch((error) => ErrorToast(error));
  };

  console.log(t);

  return (
    <>
      {/* <Modals/> */}
      <div className="row row-sm">
        <div className="col-12">
          <div className="card">
            <div
              className={`card-header pb-0 d-flex justify-content-between align-items-center flex-column flex-md-row ${
                !showForm && 'pb-4'
              }`}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <h4 className="card-title mg-b-0">
                    {t('Tenders.create_new_tender')}
                  </h4>
                </div>
                <p className="tx-12 tx-gray-500 mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </p>
              </div>
              {!showForm && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setShowForm(!showForm);
                    setShowEditTender(false);
                    localStorage.removeItem('selectedCompanyID');
                  }}
                >
                  {t('Tenders.add_tender')}
                </button>
              )}
            </div>
            {showForm && (
              <div className="card-body">
                <TenderCreate
                  changeHandler={changeHandler}
                  changeSelectHandler={changeSelectHandler}
                  companyList={companyList}
                  typeList={typeList}
                  locationList={locationList}
                  supervisorList={supervisorList}
                  commissionerList={commissionerList}
                  clientList={clientList}
                  triggerRerender={triggerRerender}
                  setTriggerRerender={setTriggerRerender}
                  setSelectedData={setSelectedData}
                  selectedData={selectedData}
                  storedCompanyID={storedCompanyID}
                />
                <div className="d-flex justify-content-end w-100">
                  <button
                    onClick={createData}
                    className="btn btn-primary mt-3 mb-0"
                  >
                    {t('Tenders.create_tender')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {showEditTender && (
        <div className="row row-sm" id="edit-tender-content">
          <div className="col-12">
            <div className="card">
              <div
                className={`card-header pb-0 d-flex justify-content-between align-items-center flex-column flex-md-row ${
                  !showForm && 'pb-4'
                }`}
              >
                <div>
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-0">{`Tender - ID: ${selectedData?.tender?.id}`}</h4>
                  </div>
                  <p className="tx-12 tx-gray-500 mb-2">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </p>
                </div>
              </div>

              <TenderUpdate
                storedCompanyID={storedCompanyID}
                setShowEditTender={setShowEditTender}
                triggerRerender={triggerRerender}
                setTriggerRerender={setTriggerRerender}
                companyList={companyList}
                typeList={typeList}
                locationList={locationList}
                commissionerList={commissionerList}
                selectedData={selectedData}
                clientList={clientList}
              />
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 d-flex justify-content-between">
              <div className="text-wrapper">
                <div className="d-flex justify-content-between">
                  <h4 className="card-title mg-b-0">
                    {t('Tenders.list_of_tenders')}
                  </h4>
                </div>
                <p className="tx-12 tx-gray-500 mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </p>
              </div>
              <button onClick={changeList} className="btn btn-primary">
                {!isDeletedList
                  ? t('Tenders.show_deleted_tenders')
                  : t('Tenders.show_all_tenders')}
              </button>
            </div>
            <div className="example border-none">
              <div className="panel panel-primary tabs-style-2">
                <div className=" tab-menu-heading">
                  <div className="tabs-menu1">
                    <ul className="nav panel-tabs main-nav-line text-uppercase">
                      <li>
                        <a
                          href="#tab0"
                          className="nav-link active"
                          data-bs-toggle="tab"
                          onClick={() => filterTenderStatus('', 'status')}
                        >
                          {t('Tenders.all_tenders')}
                        </a>
                      </li>
                      {tenderStatus?.map((status, i) => {
                        return (
                          <li key={i}>
                            <a
                              href={`#tab${i + 1}`}
                              className="nav-link"
                              onClick={() =>
                                filterTenderStatus(status, 'status')
                              }
                              data-bs-toggle="tab"
                            >
                              {status === 'pending'
                                ? t('Tenders.pending')
                                : status === 'in_progress'
                                ? t('Tenders.in_progress')
                                : status === 'done'
                                ? t('Tenders.done')
                                : status}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="panel-body tabs-menu-body main-content-body-right border">
                  <TenderFiltering
                    filterTenderStatus={filterTenderStatus}
                    tenderStatus={tenderStatus}
                    companyList={companyList}
                    filterData={filterData}
                    getCurrentDataID={getCurrentDataID}
                    deleteTender={deleteTender}
                    clientList={clientList}
                    listOfAllData={listOfAllData!}
                    setShowEditTender={setShowEditTender}
                    scrollIntoView={scrollIntoView}
                    setShowForm={setShowForm}
                    isDeletedList={isDeletedList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex align-items-center justify-content-end">
        {totalPages <= 1 ? (
          ''
        ) : (
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePagination={handlePages}
          />
        )}
      </div>
    </>
  );
}
