import React, { useEffect, useState } from 'react';
import { Pagination } from '../../../shared/paginations/Paginations';
import { Modals } from '../../../shared/modals/Modals';
import { useTender } from '../../../shared/hooks/app/useTender';
import {
  addCompanies,
  deleteCompany,
  getCompanies,
  getCompany,
  putCompanies,
} from '../Companies.service';
import { CompaniesTable } from './CompaniesTable';
import { CompaniesCreate } from './CompaniesCreate';
import { CompaniesUpdate } from './CompaniesUpdate';
import {
  iCrud,
  iPagination,
  iResponseCrud,
} from '../../../model/app/Application';
import { searchDto } from '../../../shared/functions/Functions';
import _debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
export function CompaniesContent() {
  const { t } = useTranslation();
  const { showForm, deleteFile, setShowForm, modalRef } = useTender({
    getData: getCompany,
    putData: putCompanies,
    deleteData: deleteCompany,
    getMany: getCompanies,
    addData: addCompanies,
  });
  const [selectedData, setSelectedData] = useState<iCrud>();
  const [listOfAllData, setListOfAllData] = useState<iResponseCrud[]>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState<iPagination>({
    page: 1,
    perPage: 10,
    title: '',
    email: '',
  });
  const [triggerRerender, setTriggerRerender] = useState(false);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const filterData = _debounce(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setPagination((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    },
    100
  );

  const getCurrentDataID = (id: number) => {
    localStorage.setItem('selectedCompanyID', JSON.stringify(id));
    getCompany(id)
      .then((res) => {
        setSelectedData(res);
      })
      .catch((error) => error);
  };

  useEffect(() => {
    const paginationState = searchDto(pagination);
    getCompanies(paginationState)
      .then((data) => {
        if (data) {
          setTotalPages(Math.ceil(data?.count / data?.perPage));
          setListOfAllData(data?.data);
        }
      })
      .catch((error) => error);
  }, [
    JSON.stringify(triggerRerender),
    JSON.stringify(page),
    JSON.stringify(pagination),
  ]);

  return (
    <>
      <div className="row row-sm">
        <div className="col-12">
          <div className="card">
            <div
              className={`card-header pb-0 d-flex justify-content-between align-items-center flex-column flex-md-row ${
                !showForm && 'pb-4'
              }`}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <h4 className="card-title mg-b-0">
                    {t('Companies.create_new_company')}
                  </h4>
                </div>
                <p className="tx-12 tx-gray-500 mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </p>
              </div>
              {!showForm && (
                <button
                  className="btn btn-primary"
                  onClick={() => setShowForm(!showForm)}
                >
                  {t('Companies.add_company')}
                </button>
              )}
            </div>
            {showForm && (
              <div className="card-body">
                <CompaniesCreate
                  setShowForm={setShowForm}
                  setTriggerRerender={setTriggerRerender}
                  triggerRerender={triggerRerender}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <CompaniesTable
          getCurrentDataID={getCurrentDataID}
          listOfAllData={listOfAllData!}
          filterData={filterData}
        />
      </div>
      <div className="w-100 d-flex align-items-center justify-content-end">
        {totalPages <= 1 ? (
          ''
        ) : (
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePagination={handlePages}
          />
        )}
      </div>
      <CompaniesUpdate
        selectedData={selectedData!}
        setSelectedData={setSelectedData!}
        triggerRerender={triggerRerender}
        setTriggerRerender={setTriggerRerender}
        modalRef={modalRef}
      />

      <Modals
        modalId="deleteFile"
        buttonType="btn-secondary"
        title="Are you sure you want to delete this file?"
        saveButtonName="Delete"
        updateChanges={() =>
          deleteFile(Number(localStorage.getItem('fileIDDelete')))
        }
      />
    </>
  );
}
