import React from "react";
import {Pagination} from "../../../shared/paginations/Paginations";
import {useTender} from "../../../shared/hooks/app/useTender";
import {
    addCancellation,
    deleteCancellation,
    getCancellation,
    getCancellations,
    putCancellations
} from "../Cancellation.service";
import {CancellationCreate} from "./CancellationCreate";
import {CancellationTable} from "./CancellationTable";
import {CancellationUpdateModal} from "./CancellationUpdateModal";
import {CancellationDeleteModal} from "./CancellationDeleteModal";

export function CancellationContent() {

    const {
        page,
        handlePages,
        totalPages,
        listOfAllData,
        removeData,
        updateData,
        selectedModalData,
        createData,
        getCurrentDataID,
        changeHandler,
        showForm,
        filterData,
        setShowForm,
        selectedData
    } = useTender({
        getData: getCancellation,
        putData: putCancellations,
        deleteData: deleteCancellation,
        addData: addCancellation,
        getMany: getCancellations
    });

    return (
        <>
            <div className="row row-sm">
                <CancellationCreate showForm={showForm} setShowForm={setShowForm} createData={createData}
                                    changeHandler={changeHandler}/>
            </div>

            <div className="row">
                <CancellationTable filterData={filterData} listOfAllData={listOfAllData}
                                   getCurrentDataID={getCurrentDataID}/>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-end">
                {totalPages <= 1 ? '' :
                    <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                }
            </div>
            <CancellationUpdateModal selectedData={selectedData!} changeHandler={changeHandler}
                                     updateData={updateData}/>
            <CancellationDeleteModal removeData={removeData} selectedData={selectedData}
                                     selectedModalData={selectedModalData}/>
        </>
    )
}
