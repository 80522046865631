import { useTender } from '../../../shared/hooks/app/useTender';
import React from 'react';
import { FunctionCreate } from './FunctionCreate';
import { FunctionTable } from './FunctionTable';
import {
  addFunction,
  deleteFunction,
  getFunction,
  getFunctions,
  putFunction,
} from '../Functions.service';
import { Pagination } from '../../../shared/paginations/Paginations';
import { FunctionsUpdateModal } from './FunctionsUpdateModal';
import { FunctionDeleteModal } from './FunctionDeleteModal';

export const FunctionsContent = () => {
  const {
    page,
    handlePages,
    totalPages,
    listOfAllData,
    updateData,
    createData,
    removeData,
    selectedModalData,
    getCurrentDataID,
    filterData,
    changeHandler,
    showForm,
    setShowForm,
  } = useTender({
    getData: getFunction,
    putData: putFunction,
    deleteData: deleteFunction,
    getMany: getFunctions,
    addData: addFunction,
  });
  return (
    <>
      <div className="row row-sm">
        <FunctionCreate
          showForm={showForm}
          setShowForm={setShowForm}
          createData={createData}
          changeHandler={changeHandler}
        />
      </div>
      <div className="row">
        <FunctionTable
          filterData={filterData}
          getCurrentDataID={getCurrentDataID}
          listOfAllData={listOfAllData}
        />
      </div>
      <div className="w-100 d-flex align-items-center justify-content-end">
        {totalPages <= 1 ? (
          ''
        ) : (
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePagination={handlePages}
          />
        )}
      </div>
      <FunctionsUpdateModal
        selectedData={selectedModalData!}
        changeHandler={changeHandler}
        updateData={updateData}
      />
      <FunctionDeleteModal
        removeData={removeData}
        selectedModalData={selectedModalData!}
      />
    </>
  );
};
