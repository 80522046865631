import {iCrud, iGetData} from "../../model/app/Application";
import {requests} from "../../utils/helpers/api.services";
import {BASE_PATH} from "../../utils/helpers/api.routes";
import {ErrorToast} from "../../utils/toasters/toasters";

export function addType(body: iCrud) {
    return requests.post(`${BASE_PATH}/admin/tender-types`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getTypes(body?: iGetData) {
    return requests.get(`${BASE_PATH}/admin/tender-types`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getType(id: number) {
    return requests.get(`${BASE_PATH}/admin/tender-types/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function putType(id: number, body?: iCrud) {
    return requests.put(`${BASE_PATH}/admin/tender-types/${id}`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function deleteType(id:number) {
    return requests.delete(`${BASE_PATH}/admin/tender-types/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error));
}