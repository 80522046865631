import React from 'react';
import Select from 'react-select';
import { themeSelect } from './TenderFiltering';
import { iTenderCreate } from '../../../model/app/Application';
import { useTenderSelect } from '../../../shared/hooks/app/useTenderSelect';
import { useTranslation } from 'react-i18next';
export const TenderCreate = ({
  changeHandler,
  changeSelectHandler,
  storedCompanyID,
  commissionerList,
  locationList,
  supervisorList,
  clientList,
  companyList,
  selectedData,
  typeList,
}: iTenderCreate) => {
  const {
    optionsCommissioner,
    optionsTenderType,
    optionsLocation,
    optionsSupervisor,
    optionsCompany,
    optionsClient,
  } = useTenderSelect({
    commissionerList,
    locationList,
    supervisorList,
    clientList,
    companyList,
    typeList,
    selectedData,
  });
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col-md-3 mt-3">
        <label htmlFor="title">
          <span className="text-danger">*</span>
          {t('Tenders.title')}
        </label>
        <input
          type="text"
          className="form-control"
          name="title"
          onChange={changeHandler}
          placeholder="Enter title"
        />
      </div>

      <div className="col-md-3 mt-3">
        <label htmlFor="commissioner">{t('Tenders.commissioner')}</label>
        <Select
          options={optionsCommissioner}
          onChange={changeSelectHandler}
          id="custom-select2"
          theme={themeSelect}
        />
      </div>

      <div className="col-md-3 mt-3">
        <label htmlFor="location">{t('Tenders.location')}</label>
        <Select
          options={optionsLocation}
          onChange={changeSelectHandler}
          id="custom-select2"
          theme={themeSelect}
        />
      </div>

      <div className="col-md-3 mt-3">
        <label htmlFor="tenderType">{t('Tenders.tender_type')}</label>

        <Select
          options={optionsTenderType}
          onChange={changeSelectHandler}
          id="custom-select2"
          theme={themeSelect}
        />
      </div>

      <div className="col-md-8 mt-3">
        <label htmlFor="description">{t('Tenders.description')}</label>
        <textarea
          className="form-control"
          name="description"
          rows={7}
          onChange={changeHandler}
          placeholder="Enter description"
        />
      </div>

      <div className="col-md-4">
        <div className="row">
          <div className="col-12 mt-3">
            <label htmlFor="maxRevenue">{t('Tenders.max_revenue')}</label>
            <input
              type="number"
              className="form-control"
              name="maxRevenue"
              onChange={changeHandler}
              placeholder="Enter maxRevenue"
            />
          </div>

          <div className="col-12 mt-3">
            <label htmlFor="applicationDeadline">
              <span className="text-danger">*</span>
              {t('Tenders.deadline')}
            </label>
            <input
              type="date"
              className="form-control"
              name="applicationDeadline"
              onChange={changeHandler}
              placeholder="Enter application deadline"
            />
          </div>

          <div className="col-12 mt-3">
            <label htmlFor="dateOfTenderAwardance">
              {t('Tenders.awardance_date')}
            </label>
            <input
              type="date"
              className="form-control"
              name="dateOfTenderAwardance"
              onChange={changeHandler}
              placeholder="Enter date of tender awardance"
            />
          </div>
        </div>
      </div>
      <div className="col-md-4 mt-3">
        <label htmlFor="source">{t('Tenders.source')}</label>
        <input
          type="text"
          className="form-control"
          name="source"
          onChange={changeHandler}
          placeholder="Enter source"
        />
      </div>

      <div className="col-md-4 mt-3">
        <label htmlFor="link">{t('Tenders.link')}</label>
        <input
          type="text"
          className="form-control"
          name="link"
          onChange={changeHandler}
          placeholder="Enter link"
        />
      </div>

      <div className="col-md-2 mt-3">
        <label htmlFor="startDate">
          <span className="text-danger">*</span>
          {t('Tenders.start_date')}
        </label>
        <input
          type="date"
          className="form-control"
          name="startDate"
          onChange={changeHandler}
          placeholder="Enter startDate"
        />
      </div>
      <div className="col-md-2 mt-3">
        <label htmlFor="endDate">
          {' '}
          <span className="text-danger">*</span>
          {t('Tenders.end_date')}
        </label>
        <input
          type="date"
          className="form-control"
          name="endDate"
          onChange={changeHandler}
          placeholder="Enter endDate"
        />
      </div>
      <div className="col-md-1 mt-3 d-flex flex-column">
        <label htmlFor="isProlongation">{t('Tenders.prolongation')}</label>

        <label className="switch">
          <input
            type="checkbox"
            name="isProlongation"
            onChange={changeHandler}
          />
          <span className="slider round" />
        </label>
      </div>

      <div className="col-md-2 mt-3">
        <label htmlFor="loss">Los</label>
        <input
          type="text"
          className="form-control"
          name="loss"
          onChange={changeHandler}
          placeholder="Enter los"
        />
      </div>
      <div className="col-md-2 mt-3">
        <label htmlFor="personalRequired">
          {t('Tenders.personal_required')}
        </label>
        <input
          type="text"
          className="form-control"
          name="personalRequired"
          onChange={changeHandler}
          placeholder="Enter Personal required"
        />
      </div>

      <div className="col-md-3 mt-3">
        <label htmlFor="spaseRequired">
          {' '}
          {t('Tenders.space')} / {t('Tenders.room_required')}
        </label>
        <input
          type="text"
          className="form-control"
          name="spaseRequired"
          onChange={changeHandler}
          placeholder="Enter Spase required"
        />
      </div>

      <div className="col-md-3 mt-3">
        <label htmlFor="spaseRequired">{t('Tenders.technical_required')}</label>
        <input
          type="text"
          className="form-control"
          name="technicalRequirements"
          onChange={changeHandler}
          placeholder="Enter technical requirements"
        />
      </div>

      <div className="col-12 mt-5">
        <h5>{t('Tenders.relevance_check_employee')}</h5>
      </div>
      <div className="col-md-4 mt-2">
        <label htmlFor="company">
          <span className="text-danger">*</span>
          {t('Tenders.select_company')}
        </label>
        <Select
          options={optionsCompany}
          onChange={changeSelectHandler}
          id="custom-select2"
          theme={themeSelect}
        />
      </div>
      {storedCompanyID && (
        <div className="col-md-4 mt-2">
          <label htmlFor="company">
            <span className="text-danger">*</span>
            {t('Tenders.select_supervisor')}
          </label>
          <Select
            options={optionsSupervisor}
            onChange={changeSelectHandler}
            id="custom-select2"
            theme={themeSelect}
          />
        </div>
      )}
    </div>
  );
};
