import {iCrud, iGetData} from "../../model/app/Application";
import {requests} from "../../utils/helpers/api.services";
import {BASE_PATH} from "../../utils/helpers/api.routes";
import {ErrorToast} from "../../utils/toasters/toasters";

export function addFunction(body: iCrud) {
    return requests.post(`${BASE_PATH}/admin/functions`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getFunction(id: number) {
    return requests.get(`${BASE_PATH}/admin/functions/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function putFunction(id: number, body?: iCrud) {
    return requests.put(`${BASE_PATH}/admin/functions/${id}`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function getFunctions(body?: iGetData) {
    return requests.get(`${BASE_PATH}/admin/functions`, body)
        .then(data => data)
        .catch(error => ErrorToast(error));
}

export function deleteFunction(id: number) {
    return requests.delete(`${BASE_PATH}/admin/functions/${id}`)
        .then(data => data)
        .catch(error => ErrorToast(error));
}