import { iCrud, iResponseCrud } from '../../../model/app/Application';
import { Modals } from '../../../shared/modals/Modals';
import React, { SetStateAction } from 'react';
import { putUser } from '../Users.service';
import { ErrorToast, SuccessToast } from '../../../utils/toasters/toasters';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { BASE_PATH } from '../../../utils/helpers/api.routes';
import { useTranslation } from 'react-i18next';
interface iUserUpdateModal {
  companyList: iResponseCrud[];
  userRoleList: string[];
  modalRef: React.MutableRefObject<any>;
  selectedData: iCrud | undefined;
  setTriggerRerender: React.Dispatch<SetStateAction<boolean>>;
  triggerRerender: boolean;
  setSelectedData: React.Dispatch<SetStateAction<iCrud | undefined>>;
}

export const UserUpdateModal = ({
  userRoleList,
  companyList,
  setTriggerRerender,
  triggerRerender,
  modalRef,
  selectedData,
  setSelectedData,
}: iUserUpdateModal) => {
  const { t } = useTranslation();
  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setSelectedData((prev: iCrud | undefined) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const updateData = () => {
    const modalData = new FormData();
    if (modalRef?.current?.files?.length) {
      for (let file in modalRef.current.files) {
        modalData.append('file', modalRef.current.files[file]);
      }
      if (selectedData! === undefined) {
        modalData.append('body', JSON.stringify({}));
      }
    }
    modalData.append(
      'body',
      JSON.stringify({
        ...selectedData,
        company: +selectedData?.company!
          ? +selectedData?.company!
          : +selectedData?.company?.id!,
        roles: [
          !Array.isArray(selectedData?.roles)
            ? selectedData?.roles
            : selectedData?.roles[0],
        ],
      })
    );

    selectedData &&
      putUser(selectedData?.id as number, modalData as any)
        .then((res) => {
          if (res) {
            SuccessToast('You have successfully updated user');
            setTriggerRerender(!triggerRerender);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  return (
    <Modals
      modalId="modaldemo1"
      title={`User - ID: ${selectedData?.id}`}
      body={
        <div className="form-group mb-0">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="firstName">{t('Users.first_name')}</label>
              <input
                type="text"
                className="form-control"
                name="firstName"
                defaultValue={selectedData?.firstName}
                key={selectedData?.firstName}
                onBlur={changeHandler}
                placeholder={t('Users.enter_first_name')}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="mt-3 mt-md-0">
                {t('Users.last_name')}
              </label>
              <input
                type="text"
                className="form-control"
                name="lastName"
                defaultValue={selectedData?.lastName}
                key={selectedData?.lastName}
                onBlur={changeHandler}
                placeholder={t('Users.enter_last_name')}
              />
            </div>

            <div className="col-md-12">
              <label htmlFor="email" className="mt-3">
                {t('Users.email')}
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                defaultValue={selectedData?.email}
                key={selectedData?.email}
                onBlur={changeHandler}
                placeholder={t('Users.enter_email')}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="company" className="mt-3">
                {t('Users.select_company')}
              </label>
              <select
                name="company"
                className="form-control form-select select2"
                onBlur={changeHandler}
                key={selectedData?.company?.id}
                data-bs-placeholder={t('Users.select_company')}
              >
                <option value={selectedData?.company?.id} hidden>
                  {selectedData?.company?.title}
                </option>
                {companyList?.map((company: iResponseCrud) => {
                  return (
                    <option value={company?.id} key={company?.id}>
                      {company?.title}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="roles" className="mt-3">
                {t('Users.roles')}
              </label>
              <select
                name="roles"
                className="form-control form-select select2"
                onBlur={changeHandler}
                data-bs-placeholder={t('users.select_role')}
              >
                <option value={`${selectedData?.roles}`} hidden>
                  {selectedData?.roles}
                </option>
                {userRoleList?.map((role: string, i: number) => {
                  return (
                    <option value={role} key={i}>
                      {role?.split('_').join(' ')}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-12 mt-3">
              <label htmlFor="file">{t('Users.profile_picture')}</label>
              <div className="form-control d-flex align-items-center justify-content-between px-0">
                <input
                  type="file"
                  onChange={changeHandler}
                  name="file"
                  ref={modalRef}
                />
                {selectedData?.profilePicture !== null && (
                  <a
                    href={`${BASE_PATH}/${selectedData?.profilePicture?.path}`}
                    target="_blank"
                  >
                    <VisibilityIcon
                      className={`text-gray me-2 cursor-pointer`}
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      updateChanges={updateData}
    />
  );
};
