import { Modals } from '../../../shared/modals/Modals';
import React from 'react';
import { iDataUpdateModalProps } from '../../../model/app/Application';
import { useTranslation } from 'react-i18next';
export const TypeUpdateModal = ({
  selectedData,
  changeHandler,
  updateData,
}: iDataUpdateModalProps) => {
  const { t } = useTranslation();
  return (
    <Modals
      modalId="modaldemo1"
      title={`${t('Type.type')} - ID: ${selectedData?.id}`}
      body={
        <div className="form-group mb-0">
          <label htmlFor="exampleInputEmail1">{t('Type.title')}</label>
          <input
            type="text"
            className="form-control updateModalField"
            name="title"
            key={selectedData?.title}
            defaultValue={selectedData?.title}
            onBlur={changeHandler}
            placeholder={t('Type.enter_title')}
          />
        </div>
      }
      updateChanges={updateData!}
    />
  );
};
