import React from 'react';
import { Pagination } from '../../../shared/paginations/Paginations';
import { useTender } from '../../../shared/hooks/app/useTender';
import {
  addLocations,
  getLocation,
  getLocations,
  putLocations,
} from '../Location.service';
import { LocationCreate } from './LocationCreate';
import { LocationTable } from './LocationTable';
import { LocationUpdateModal } from './LocationUpdateModal';

export function LocationContent() {
  const {
    page,
    handlePages,
    totalPages,
    listOfAllData,
    updateData,
    createData,
    getCurrentDataID,
    changeHandler,
    showForm,
    filterData,
    setShowForm,
    selectedData,
  } = useTender({
    getData: getLocation,
    putData: putLocations,
    getMany: getLocations,
    addData: addLocations,
  });

  return (
    <>
      <div className="row row-sm">
        <LocationCreate
          showForm={showForm}
          setShowForm={setShowForm}
          createData={createData}
          changeHandler={changeHandler}
        />
      </div>

      <div className="row">
        <LocationTable
          listOfAllData={listOfAllData}
          getCurrentDataID={getCurrentDataID}
          filterData={filterData}
        />
      </div>
      <div className="w-100 d-flex align-items-center justify-content-end">
        {totalPages <= 1 ? (
          ''
        ) : (
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePagination={handlePages}
          />
        )}
      </div>
      <LocationUpdateModal
        selectedData={selectedData!}
        changeHandler={changeHandler}
        updateData={updateData}
      />
    </>
  );
}
