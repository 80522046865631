import { Header } from './Header';
import { SideBar } from './SideBar';
import { Footer } from './Footer';
import { Link } from 'react-router-dom';
import { iPageTemplate } from '../../model/app/Application';
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Construction } from '@mui/icons-material';

export function PageTemplate({ content, pageName, title }: iPageTemplate) {
  const { t } = useTranslation();

  console.log(title);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [isLoading, setIsLoading] = useState(false);

  var numberOfAjaxCAllPending = 0;

  axios.interceptors.request.use(
    function (config) {
      numberOfAjaxCAllPending++;
      setIsLoading(true);
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      numberOfAjaxCAllPending--;

      if (+numberOfAjaxCAllPending === 0) {
        setIsLoading(false);
      }
      return response;
    },
    function (error) {
      numberOfAjaxCAllPending--;
      if (+numberOfAjaxCAllPending === 0) {
        setIsLoading(false);
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      {isLoading && (
        <div id="global-loader">
          <img src="/images/loader.svg" className="loader-img" alt="Loader" />
        </div>
      )}
      <div className="page">
        <div>
          <Header />
          <SideBar />
        </div>
        {/* main content */}
        <div className="main-content app-content">
          {/* container */}
          <div className="main-container container-fluid">
            {/* breadcrumb */}
            <div className="breadcrumb-header justify-content-between mt-5 pt-5">
              <div className="left-content">
                <span className="main-content-title mg-b-0 mg-b-lg-1 text-uppercase">
                  {pageName && t(`Titles.${pageName.toLowerCase()}`)}
                </span>
              </div>
              <div className="justify-content-center mt-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item tx-15">
                    {title && t(`Titles.${t(pageName.toLowerCase())}`)}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {pageName && t(`Titles.${pageName.toLowerCase()}`)}
                  </li>
                </ol>
              </div>
            </div>
            {/* /breadcrumb */}
            {content}
          </div>
          {/*  container closed  */}
        </div>
        {/* main content closed */}
        <Footer />
        <Link to="#" id="back-to-top" onClick={scrollToTop} className="d-block">
          <i className="las la-arrow-up" />
        </Link>
      </div>
    </>
  );
}
