import { PageTemplate } from '../../shared/layout/PageTemplate';
import { CancellationContent } from './components/CancellationContent';

export function Cancellation() {
  return (
    <PageTemplate
      pageName="Cancellation"
      title="Enum"
      content={<CancellationContent />}
    />
  );
}
