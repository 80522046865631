import axios, {AxiosResponse} from 'axios';


const defaultOptions = {
    headers: {
        'Content-Type': 'application/json',
        'accept': 'text/html',
    },
    validateStatus: function (status: number) {
        return status >= 200 && status < 300
    }
};

export const instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token") ?? "{}"
    config!.headers!.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

const responseBody = (response: AxiosResponse) => response.data;
const rejectResponse = (arg: any) => Promise.reject(arg)


export const requests = {
    get: (url: string, params?: any) => instance.get(url, {params}).then(responseBody).catch(e => {
        throw e
    }),
    post: (url: string, body: {}, config?: any) => instance.post(url, body, config).then(responseBody, rejectResponse).catch(e => {
        throw e
    }),
    put: (url: string, body?: {}) => instance.put(url, body).then(responseBody).catch(e => {
        if (e.message === 'Request failed with status code 500') {
            window.location.href = '/500';
        }
        throw e

    }),
    patch: (url: string, body: {}) => instance.patch(url, body).then(responseBody).catch(e => {
        throw e
    }),
    delete: (url: string) => instance.delete(url).then(responseBody).catch(e => {
        throw e
    }),
};


export function multipartFormData(files: any, url: string, body?: any, method = 'put') {
    const formData = new FormData();
    if (files?.current?.files?.length) {
        for (let file in files?.current?.files) {
            formData.append('file', files.current.files[file]);
        }
    }
    formData.append('body', JSON.stringify(body));
    if (method === 'put') {
        return instance.put(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } else {
        return instance.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}
